import React, { FC, Suspense } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { PaymentStatus } from './CashRegisterPayment/CashRegisterPayment.types';
import { PaymentContent } from './PaymentContent';

interface PaymentProps {
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  paymentUrl?: string;
  onSubmitSuccess: () => void;
  isFormCompleted: boolean;
  paymentStatus?: PaymentStatus;
  claimGroupId: string;
}

export const Payment: FC<PaymentProps> = ({
  paymentUrl,
  invoice,
  isFormCompleted,
  onSubmitSuccess,
  paymentStatus,
  claimGroupId,
}) => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <PaymentContent
        paymentUrl={paymentUrl}
        invoice={invoice}
        isFormCompleted={isFormCompleted}
        onSubmitSuccess={onSubmitSuccess}
        paymentStatus={paymentStatus}
        claimGroupId={claimGroupId}
      />
    </Suspense>
  );
};

Payment.displayName = 'Payment';
