import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ActorTypes,
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  AdditionalClaimInformation,
  BackResponse,
  BackResponseFailure,
  Document,
  ReverseMoney,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import apiCall from '../../services/apiCall';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/full`;

export interface GetFullClaimContextQueryReturnValues {
  actorId?: string;
  actorType: ActorTypes;
  sellerId: string;
  declarationDate: string;
  issueId: string;
  issueName?: string;
  issueUniqueName?: string;
  reasonId: string;
  reasonName?: string;
  reasonUniqueName?: string;
  solutionId: string;
  solutionName?: string;
  solutionUniqueName?: string;
  solutionPrice: ReverseMoney;
  price: number;
  currencyCode?: string;
  warrantyTypeId: string;
  warrantyTypeName?: string;
  warrantyTypeUniqueName?: string;
  fromProductWarranty: boolean;
  depositStoreId?: string;
  comments: {
    type: string;
    content?: string;
  }[];
  documents?: Document[];
  diagnosticHistorics: {
    type?: string;
    content?: string;
    anwser?: string;
    endQuestion?: string;
  }[];
  additionalInformation?: AdditionalClaimInformation[];
  matchingActors?: {
    score: number;
    priority: number;
    identity: {
      id: string;
      type: ActorTypes;
    };
    hasValidAddress: boolean;
  };
}

interface GetFullClaimContextQueryArgs {
  claimId: string;
}

const getFullClaimContextQuery = async ({
  claimId,
}: GetFullClaimContextQueryArgs): Promise<
  BackResponse<GetFullClaimContextQueryReturnValues> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<
      BackResponse<GetFullClaimContextQueryReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getFullClaimContextQuery;
