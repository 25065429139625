import React, { FC } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { Paragraph } from '../Paragraph';

import $EmptyPlaceholder from './EmptyPlaceholder';

interface EmptyPlaceHolderWithIllustrationProps {
  illustration: React.ReactElement;
  texts: MessageType[];
}

export const EmptyPlaceHolderWithIllustration: FC<
  EmptyPlaceHolderWithIllustrationProps
> = ({ illustration, texts }) => {
  return (
    <$EmptyPlaceholder>
      {illustration}
      {texts.map((text) => (
        <Paragraph key={text.id}>
          <SafeFormattedMessageWithoutSpread message={text} />
        </Paragraph>
      ))}
    </$EmptyPlaceholder>
  );
};

EmptyPlaceHolderWithIllustration.displayName =
  'EmptyPlaceHolderWithIllustration';
