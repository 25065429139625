import { matchPath } from 'react-router-dom';
import { ComplexSwitchSide } from './ComplexSwitch';

interface GetDefaultValueArgs {
  defaultValue?: number;
  url: string;
  leftSide?: ComplexSwitchSide;
  rightSide?: ComplexSwitchSide;
}

export const getDefaultValue = ({
  defaultValue,
  url,
  leftSide,
  rightSide,
}: GetDefaultValueArgs): number => {
  if (defaultValue === undefined && matchPath(url, { path: leftSide?.path })) {
    return 0;
  }
  if (defaultValue === undefined && matchPath(url, { path: rightSide?.path })) {
    return 1;
  }
  if (defaultValue !== undefined && !leftSide?.label) {
    return 1;
  }
  if (defaultValue !== undefined && !rightSide?.label) {
    return 0;
  }

  return defaultValue || 0;
};
