import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Col } from 'react-styled-flexboxgrid';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { PositionIcon } from '@savgroup-front-common/core/src/protons/icons';

import {
  $ColWithoutPaddingRight,
  $DropdownSuggestions,
  $LocationField,
  $RowStyled,
  $Suggestion,
} from './Address.styles';
import messages from './messages';

class Address extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: false,
    placeId: '',
    isGoogleAddress: false,
  };

  UNSAFE_componentWillMount() {
    const { searchAddress } = this.props;

    if (searchAddress) {
      this.callbackAddressSelected(searchAddress);
    }
  }

  onError = (error) => {
    logError(error);
  };

  onSuccess = (pos) => {
    const crd = pos.coords;
    const latLng = { lat: crd.latitude, lng: crd.longitude };
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        const address = results[0];

        if (address) {
          const countryCode = get(
            find(address.address_components, (info) =>
              includes(get(info, 'types'), 'country'),
            ),
            'short_name',
          );

          this.handleOnLocalize(address.formatted_address, countryCode);
        } else {
          logError('No results found');
        }
      } else {
        logError(`Geocoder failed due to: ${status}`);
      }
    });
  };

  getCurrentPosition = () => {
    this.setState(() => ({ isGettingCurrentLocation: true }));
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        resolve,
        reject,
        this.onError,
        options,
      ),
    )
      .then((data) => {
        self.setState(() => ({ isGettingCurrentLocation: false }));

        return this.onSuccess(data);
      })
      .catch((e) => {
        self.setState(() => ({ isGettingCurrentLocation: false }));

        return logError(e);
      });
  };

  handleOnLocalize = (address, countryCode) => {
    this.setState({
      address,
    });
    if (address) {
      this.callbackAddressSelected({ address, countryCode });
    }
  };

  handleChange = (address) => {
    this.setState({
      address,
    });
  };

  handleSelect = (address, placeId, isGoogleAddress) => {
    this.setState({
      loading: true,
      placeId,
      isGoogleAddress,
      address,
    });
    const { onAddressSelected, maxDistance } = this.props;

    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({
            loading: false,
          });
          const country = results[0].address_components.find((c) =>
            c.types.some((t) => t === 'country'),
          );
          const city = results[0].address_components.find((c) =>
            c.types.some((t) => t === 'locality'),
          );
          const countryCode = country ? country.short_name : null;

          if (countryCode) {
            onAddressSelected({
              address,
              location: { lat, lng },
              placeId,
              countryCode,
              city: city ? city.long_name : null,
              maxDistance,
            });
          } else {
            logError('APP-MissingCountryCode', {
              countryCode,
              address,
              lat,
              lng,
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  callbackAddressSelected = ({ address, countryCode }) => {
    const { onAddressSelected } = this.props;

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        onAddressSelected({
          address,
          location: latLng,
          countryCode,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Error in Address UI Component', error));
  };

  render() {
    const { onError } = this.props;
    const { isGettingCurrentLocation } = this.state;

    return (
      <PlacesAutocomplete
        value={
          get(
            this.state,
            'address',
            get(this.props, ['searchAddress', 'address']),
          ) || ''
        }
        onSelect={this.handleSelect}
        onChange={this.handleChange}
        onEnterKeyDown={this.handleSelect}
        onLocalize={this.handleOnLocalize}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <$RowStyled>
              <Col style={{ flex: 1 }}>
                <$LocationField
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                  isLoading={isGettingCurrentLocation}
                  selectAllOnFocus
                />
              </Col>
              <$ColWithoutPaddingRight>
                <Button
                  naked
                  icon={<PositionIcon />}
                  onClick={this.getCurrentPosition}
                  type={BUTTON_TYPES.BUTTON}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.aroundMe}
                  />
                </Button>
              </$ColWithoutPaddingRight>
            </$RowStyled>

            {suggestions.length > 0 ? (
              <$DropdownSuggestions>
                {loading && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loader />
                  </div>
                )}
                {suggestions.map((suggestion) => (
                  <$Suggestion
                    {...getSuggestionItemProps(suggestion)}
                    active={suggestion.active}
                    key={suggestion.description}
                  >
                    {suggestion.description}
                  </$Suggestion>
                ))}
              </$DropdownSuggestions>
            ) : null}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

Address.propTypes = {
  searchAddress: PropTypes.shape({}),
  onError: PropTypes.func,
  userAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      addressId: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
  ),
  onAddressSelected: PropTypes.func,
  isAdvancedOptionShown: PropTypes.bool,
  maxDistance: PropTypes.number.isRequired,
};
Address.defaultProps = {
  onError: () => {},
  searchAddress: null,
  userAddresses: [],
  onAddressSelected: null,
  isAdvancedOptionShown: false,
};

Address.displayName = 'Address';

export default Address;
