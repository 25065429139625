import { LANGUAGES } from '@savgroup-front-common/constants';

import { ReverseMoney } from './ReverseMoney';

export enum SELLER_ACCOUNT_STATUSES {
  UNKNOWN = 'Unknown',
  LIVE = 'Live',
  CHURNED = 'Churned',
}

export enum SF_SERVICE {
  FIELD_SERVICE_LIGHTNING = 'FieldServiceLightning',
  SERVICE_CLOUD = 'ServiceCloud',
}

export enum SALESFORCE_INTEGRATION_TYPE {
  None = 'None',
  Standard = 'Standard',
  Deprecated = 'Deprecated',
  Integration = 'Integration',
}

export interface SellerConfiguration {
  automatedMessagesEnabled: boolean;
  isClaimValidationNeeded: boolean;
  insuranceOptIn: {
    threshold: ReverseMoney;
    carriersInsuranceOptIn: {
      UPS?: boolean;
      Chronopost?: boolean;
      External?: boolean;
      Colissimo?: boolean;
      MondialRelay?: boolean;
      DPD?: boolean;
      SwissPost?: boolean;
      Teliae?: boolean;
      Warning?: boolean;
    };
    insuranceOptIn: boolean;
  };
  isSelfcareNewDesign: boolean;
  isSparePartNewModel: boolean;
  lowValueThreshold: ReverseMoney;
  deductTransportFromRefund: boolean;
  cgvUrl: string;
  ianaTimeZoneName: string;
  acceptedLanguages: LANGUAGES[];
  faqUrl?: string;
  accountManagerConfiguration: {
    sellerId: string;
    accountStatus: SELLER_ACCOUNT_STATUSES;
    customerService: boolean;
    liveDateUtc: string;
    churnDateUtc: string;
    lastChangeTimeUtc: string;
    lastChangeUser: string;
  };
  defaultOwnerOrderUrl: string;
  myAccountUrl: string;
  includedMargin: boolean;
  stripePublishableKey: string;
  ownerNotificationSenderMail: string;
  salesforceFieldServiceLightningIntegrationType: SALESFORCE_INTEGRATION_TYPE;
  salesforceServiceCloudIntegrationType: SALESFORCE_INTEGRATION_TYPE;
  carriersLabelsExpirationDelayInDays?: {
    chronopost?: number;
    colissimo?: number;
    ups?: number;
    external?: number;
    mondialRelay?: number;
  };
  isCashRegisterConnected: boolean;
  paymentUrl?: string;
  qualiReparUrl?: string;
}

export type SalesforceSellerConfiguration =
  | {
      integrationType: SALESFORCE_INTEGRATION_TYPE.Standard;
      oAut2AuthorizationServerUrl: string;
      oAut2ConsumerKey: string;
      oAut2DefaultEmail: string;
      oAut2CertificateName: string;
      apiCompositeVersion: string;
      apiCompositeUrlBase: string;
      planificationPolicy: string;
      tokenRequestEndpointUrl: string;
    }
  | {
      integrationType: Extract<
        SALESFORCE_INTEGRATION_TYPE,
        | SALESFORCE_INTEGRATION_TYPE.None
        | SALESFORCE_INTEGRATION_TYPE.Integration
        | SALESFORCE_INTEGRATION_TYPE.Deprecated
      >;
    };
