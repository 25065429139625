import React from 'react';

interface CheckCircleOutlineIconProps {
  color?: string;
}

/* eslint-disable max-len */
const CheckCircleOutlineIcon: React.FC<CheckCircleOutlineIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M11.6665 20.8333L16.6665 25.8333L28.3332 14.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33333 19.9999 3.33333C10.7949 3.33333 3.33325 10.795 3.33325 20C3.33325 29.205 10.7949 36.6667 19.9999 36.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CheckCircleOutlineIcon.displayName = 'CheckCircleOutlineIcon';

export default CheckCircleOutlineIcon;
