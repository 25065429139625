import parseInt from 'lodash/parseInt';
import React, {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import { MinusIcon, PlusIcon } from '../../../protons/icons';
import { FieldMessage } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { Label } from '../common/Label/Label';
import Input from '../Input';
import { InputProps } from '../Input/Input.types';

import {
  $MinusButtonQuantity,
  $PlusButtonQuantity,
  $QuantityContainer,
  $QuantityDisplay,
} from './QuantityInput.styles';

const stopPropagation: MouseEventHandler = (e) => e.stopPropagation();

interface QuantityInputProps extends Omit<InputProps, 'onChange'> {
  id?: string;
  hiddenMax?: number;
  max?: number;
  min?: number;
  disabled?: boolean;
  onChange: (newValue: number) => void;
  selectAllOnFocus?: boolean;
  fullWidth?: boolean;
  isSmall?: boolean;
}

interface QuantityInputPropsRef extends QuantityInputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const QuantityInput: FunctionComponent<
  React.PropsWithChildren<QuantityInputPropsRef>
> = ({
  name = 'quantity',
  onChange,
  max,
  value = 0,
  min = 0,
  disabled = false,
  isDisabled = false,
  label,
  isRequired,
  id = null,
  forwardedRef,
  hiddenMax = 99,
  postLabel,
  errors = {},
  isError = false,
  warnings = {},
  isWarning = false,
  successes = {},
  isSuccess = false,
  selectAllOnFocus = false,
  dataTestId,
  fullWidth,
  isSmall = false,
}) => {
  const transitionDisabled = isDisabled || disabled;
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [currentQuantity, setCurrentQuantity] = useState<string>(
    value.toString(),
  );

  const customMax = max || hiddenMax;

  const idFallback = useMemo(v4, []);

  const isValid = (input: number) => {
    const isMinValid = input >= min;
    const isMaxValid =
      customMax === undefined ||
      (customMax !== undefined && input <= customMax);

    return isMinValid && isMaxValid;
  };

  const handleDecrementQuantity = () => {
    if (transitionDisabled) {
      return;
    }

    const newQuantity = parseInt(currentQuantity) - 1;

    if (isValid(newQuantity)) {
      onChange(newQuantity);
      setCurrentQuantity(newQuantity.toString());
    }
  };

  const handleIncrementQuantity = () => {
    if (transitionDisabled) {
      return;
    }

    const newQuantity = parseInt(currentQuantity) + 1;

    if (isValid(newQuantity)) {
      onChange(newQuantity);
      setCurrentQuantity(newQuantity.toString());
    }
  };

  const handleEditQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (transitionDisabled) {
      return;
    }
    const current = e.target?.value;

    if (current === undefined || current === '') {
      setCurrentQuantity('0');

      return;
    }
    if (isValid(parseInt(current))) {
      onChange(parseInt(current));
      setCurrentQuantity(current);
    }
  };

  useEffect(() => {
    setCurrentQuantity(value.toString());
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(inputRef, forwardedRef);

  return (
    <>
      {label && (
        <Label
          htmlFor={id || idFallback}
          isRequired={isRequired}
          status={status}
          postLabel={postLabel}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <$QuantityContainer
        onClick={stopPropagation}
        $status={status}
        $isFocused={isFocused}
        $isHovered={isHovered}
        data-testid={dataTestId}
        fullWidth={fullWidth}
        $isSmall={isSmall}
      >
        <$MinusButtonQuantity
          primary
          icon={<MinusIcon />}
          onClick={handleDecrementQuantity}
          disabled={transitionDisabled || parseInt(value.toString()) === min}
          dataTestId={
            dataTestId ? `${dataTestId}quantityDecrement` : 'quantityDecrement'
          }
          type={BUTTON_TYPES.BUTTON}
          $isSmall={isSmall}
        />
        <$QuantityDisplay
          $disabled={transitionDisabled}
          $fullWidth={fullWidth}
          $currentValue={currentQuantity}
          $maxValue={max !== undefined ? customMax : undefined}
          $isFocused={isFocused}
          $status={status}
          onClick={() => {
            if (combinedRef && combinedRef?.current) {
              combinedRef?.current.focus();
              setIsFocused(true);
            }
          }}
          $isSmall={isSmall}
        >
          <Input
            type="text"
            min={0}
            max={max}
            name={name}
            onChange={handleEditQuantity}
            value={currentQuantity}
            isDisabled={transitionDisabled}
            ref={combinedRef}
            dataTestId="quantityInput"
            componentThemeName={SUPPORTED_COMPONENTS.QUANTITY_INPUT}
            selectAllOnFocus={selectAllOnFocus}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isCentered
            isRounded={false}
            isFocusAndHoverBehavioursHandled={false}
            isSmall={isSmall}
          />
        </$QuantityDisplay>
        <$PlusButtonQuantity
          primary
          icon={<PlusIcon />}
          onClick={handleIncrementQuantity}
          disabled={
            transitionDisabled || parseInt(value.toString()) === customMax
          }
          dataTestId={
            dataTestId ? `${dataTestId}quantityIncrement` : 'quantityIncrement'
          }
          type={BUTTON_TYPES.BUTTON}
          $isSmall={isSmall}
        />
      </$QuantityContainer>
      <FieldMessage message={message} status={status} />
    </>
  );
};

QuantityInput.displayName = 'QuantityInput';

export default React.forwardRef<HTMLInputElement, QuantityInputProps>(
  (props, ref) => <QuantityInput forwardedRef={ref} {...props} />,
);
