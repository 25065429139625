import { rem } from 'polished';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $Container = styled('div')`
  position: relative;
  width: 100%;
  padding: 1rem;
`;

export const $Button = styled(Button)`
  height: auto;
  font-size: ${rem(12)};
  color: #8a94a6;
`;
