import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import React, { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

import { INPUT_TYPES } from '@savgroup-front-common/constants';

import { safeFormattedIntlString } from '../../../formatters';
import { $Container } from '../common';
import Input from '../Input';

import { formatInputDate, formatValueToDate } from './LegacyDatepicker.helpers';
import { $Wrapper } from './LegacyDatepicker.styles';
import {
  LegacyDatePickerProps,
  LegacyDatePickerPropsRef,
} from './LegacyDatepicker.types';
import messages from './messages';

const dateFormat = 'dd/MM/yyyy';
const MOMENT_DATE_FORMAT = 'DD/MM/YYYY';

registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('de', de);

const LegacyDatepicker: FC<
  React.PropsWithChildren<LegacyDatePickerPropsRef>
> = ({
  value,
  disabled = false,
  isDisabled = false,
  isLoading = false,
  onBlur = undefined,
  onFocus = undefined,
  placeholder = messages.placeholder,
  name,
  label,
  postLabel,
  isRequired = false,
  onChange = () => undefined,
  minDate,
  maxDate,
  forwardedRef,
  dataTestId = 'date-picker',

  errors = {},
  warnings = {},
  successes = {},

  isError = false,
  isWarning = false,
  isSuccess = false,

  suffix = '',
  prefix = '',
}) => {
  const intl = useIntl();
  const transitionDisabled = disabled || isDisabled;
  const [last, setLast] = useState('');

  const shouldFormat = (value: string) => {
    if (last.length > value.length) {
      return false;
    }

    return true;
  };

  return (
    <$Container>
      <$Wrapper>
        <DatePicker
          selected={formatValueToDate(value)}
          onChange={(event) => {
            if (event === null || event === undefined) {
              onChange(undefined);

              return;
            }

            onChange(
              Array.isArray(event)
                ? [moment(event[0]), moment(event[1])]
                : moment(event),
            );
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          id={name}
          name={name}
          dropdownMode="select"
          minDate={formatValueToDate(minDate)}
          maxDate={formatValueToDate(maxDate)}
          locale={intl.locale}
          onChangeRaw={(event) => {
            if (event.type !== 'change') {
              return;
            }
            const selectionStart = event?.target?.selectionStart;
            const isFocusingLastCaracter =
              event?.target?.value?.length === selectionStart;

            if (shouldFormat(event?.target?.value)) {
              const formatedValue = formatInputDate(event.target.value);

              // eslint-disable-next-line no-param-reassign
              event.target.value = formatedValue;

              if (!isFocusingLastCaracter && selectionStart) {
                event.target.setSelectionRange(selectionStart, selectionStart);
              }
            }

            const eventMoment = moment(event.target.value, MOMENT_DATE_FORMAT);

            if (
              eventMoment.isValid() &&
              eventMoment.isBefore(maxDate) &&
              eventMoment.isAfter(minDate)
            ) {
              onChange(eventMoment);
            }

            setLast(event.target.value);
          }}
          autoComplete="off"
          ref={forwardedRef}
          customInput={
            <Input
              name={name}
              dataTestId={dataTestId}
              isRequired={isRequired}
              label={label}
              postLabel={postLabel}
              type={INPUT_TYPES.TEXT}
              isError={isError}
              isWarning={isWarning}
              isSuccess={isSuccess}
              errors={errors}
              successes={successes}
              warnings={warnings}
              disableBrowserAutocomplete
              isLoading={isLoading}
              suffix={suffix}
              prefix={prefix}
              data-type="datepicker"
              isDisabled={transitionDisabled}
            />
          }
          dateFormat={dateFormat}
          disabled={transitionDisabled}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholderText={safeFormattedIntlString(placeholder)}
        />
      </$Wrapper>
    </$Container>
  );
};

LegacyDatepicker.displayName = 'LegacyDatepicker';

export default React.forwardRef<DatePicker, LegacyDatePickerProps>(
  (props, ref) => <LegacyDatepicker forwardedRef={ref} {...props} />,
);
