import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/solutions`;

export interface Solution {
  solutionTypeId: string;
  solutionGroupId: string;
  iconKey: ICONS_TYPE;
  name: string;
  price: number;
  priceWithTax: number;
  priceCurrencyCode: string;
  didactic: string;
  key: string;
  potentialActorsId: string[];
}

export interface GetSolutionsByClaimReturnValues {
  selectedWarrantyId: string;
  selectedWarrantyName: string;
  solutions: Solution[];
}

interface GetSolutionsByClaimArgs {
  claimId: string;
}

const getSolutionsByClaim = async ({
  claimId,
}: GetSolutionsByClaimArgs): Promise<
  BackResponse<GetSolutionsByClaimReturnValues> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<
      BackResponse<GetSolutionsByClaimReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSolutionsByClaim;
