export const lazy = (factory: () => unknown) => {
  let target: unknown;

  return {
    get current() {
      if (target === undefined) {
        // eslint-disable-next-line  no-param-reassign
        target = factory();
      }

      return target;
    },
  };
};
