/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { buildPageTitle } from '../../helpers';

import ChunkLoadErrorBoundary from './ChunkLoadErrorBoundary';
import ErrorBoundyTemplate from './ErrorBoundyTemplate';
import messages from './messages';

class AppErrorBoundary extends React.Component<
  { home?: string; children: ReactNode },
  { hasError: boolean; hasChunkLoadError: boolean }
> {
  constructor(props: { home?: string; children: ReactNode }) {
    super(props);
    this.state = { hasError: false, hasChunkLoadError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });

    if (error && error.message?.includes('Loading chunk')) {
      this.setState({ hasChunkLoadError: true });
    } else {
      this.setState({ hasError: true });

      logCritical(error, { errorInfo });

      document.title = buildPageTitle([messages.title]);
    }
    logCritical(error, { errorInfo });

    document.title = buildPageTitle([messages.title]);
  }

  render() {
    const { children, home } = this.props;
    const { hasError, hasChunkLoadError } = this.state;

    if (hasChunkLoadError) {
      return <ChunkLoadErrorBoundary />;
    }

    if (hasError) {
      return <ErrorBoundyTemplate isFullScreen home={home} />;
    }

    return children;
  }
}

export default AppErrorBoundary;
