import React from 'react';

interface SoapIconProps {
  color?: string;
}

/* eslint-disable max-len */
const SoapIcon: React.FC<SoapIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 6C16.5 5.17157 17.1716 4.5 18 4.5H26C26.8284 4.5 27.5 5.17157 27.5 6C27.5 6.82843 26.8284 7.5 26 7.5H25.5V12.5H26C28.5196 12.5 30.9359 13.5009 32.7175 15.2825C34.4991 17.0641 35.5 19.4804 35.5 22V40.8C35.5 41.1546 35.4302 41.5057 35.2945 41.8332C35.1588 42.1608 34.9599 42.4585 34.7092 42.7092C34.4585 42.9599 34.1608 43.1588 33.8332 43.2945C33.5057 43.4302 33.1546 43.5 32.8 43.5H15.2C14.4839 43.5 13.7972 43.2155 13.2908 42.7092C12.7845 42.2028 12.5 41.5161 12.5 40.8V22C12.5 19.4804 13.5009 17.0641 15.2825 15.2825C17.0641 13.5009 19.4804 12.5 22 12.5H22.5V7.5H18C17.1716 7.5 16.5 6.82843 16.5 6ZM22 15.5C20.2761 15.5 18.6228 16.1848 17.4038 17.4038C16.1848 18.6228 15.5 20.2761 15.5 22V24.5H32.5V22C32.5 20.2761 31.8152 18.6228 30.5962 17.4038C29.3772 16.1848 27.7239 15.5 26 15.5H22ZM32.5 27.5H15.5V40.5H32.5V27.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

SoapIcon.displayName = 'SoapIcon';

export default SoapIcon;
