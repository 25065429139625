import React, { FC, PropsWithChildren, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import createGenericContext from '../../helpers/createGenericContext';

export interface NewUiContextArgs {
  isNewUiEnabled: boolean;
  onToggleNewBoEnabled: () => void;
}

const [useNewUiContext, NewUiContextProvider] =
  createGenericContext<NewUiContextArgs>();

const NewUiProvider: FC<PropsWithChildren<Record<never, never>>> = ({
  children,
}) => {
  const [isNewUiEnabledFromStorage, setIsNewUiEnabledFromStorage] =
    useLocalStorage<boolean>(LOCAL_STORAGE_KEYS.NEW_BO_ENABLED, false, {
      raw: false,
      serializer: (value: boolean) => {
        if (value) {
          return 'true';
        }

        return 'false';
      },
      deserializer: (value: string) => {
        return value === 'true';
      },
    });

  const onToggleNewBoEnabled = useCallback(() => {
    setIsNewUiEnabledFromStorage(!isNewUiEnabledFromStorage);

    window.location.reload();
  }, [isNewUiEnabledFromStorage, setIsNewUiEnabledFromStorage]);

  return (
    <NewUiContextProvider
      value={{
        isNewUiEnabled: isNewUiEnabledFromStorage ?? false,
        onToggleNewBoEnabled,
      }}
    >
      {children}
    </NewUiContextProvider>
  );
};

NewUiProvider.displayName = 'NewUiProvider';

export { NewUiProvider, useNewUiContext };
