/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { styledGetCustomComponentProperty } from '../../../helpers/theme';

interface FormGroupContainerProps {
  $withoutPaddingBottom?: boolean;
  $withoutPaddingTop?: boolean;
  $isOpen: boolean;
  $isFluid: boolean;
}

export const $FormGroupContainer = styled.div<FormGroupContainerProps>`
  padding-top: 0;
  margin-bottom: ${(props) => {
    if (props.$withoutPaddingBottom) {
      return '0';
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.FORM_GROUP,
      property: SUPPORTED_PROPERTIES.MARGIN_BOTTOM,
    });
  }};
  display: ${({ $isOpen }) => ($isOpen ? undefined : 'none')};
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'fit-content')};
`;
