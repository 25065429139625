import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { AddAndRemove, AddAndRemoveList } from '../../../AddAndRemove';

import AttachmentItem from './AttachmentItem';
import { Attachment } from './AttachmentItem.types';

interface AttachmentsListProps {
  fileNames: any[];
  onRemove?: (attachment: Attachment) => void;
  name: string;
}

const animationDuration = 200;

const AttachmentsList: FunctionComponent<
  React.PropsWithChildren<AttachmentsListProps>
> = ({ fileNames = [], onRemove, name }) => {
  return (
    <AddAndRemoveList component="ul" data-testid="attachementList">
      {fileNames.map((item, index) => (
        <Transition
          key={item.internalId || item.id || item.name || String(index)}
          timeout={animationDuration}
        >
          {(animationState) => (
            <AddAndRemove
              animationState={animationState}
              animationDuration={animationDuration}
            >
              <AttachmentItem item={item} onRemove={onRemove} name={name} />
            </AddAndRemove>
          )}
        </Transition>
      ))}
    </AddAndRemoveList>
  );
};

AttachmentsList.displayName = 'AttachmentsList';

export default AttachmentsList;
