import { fromJS } from 'immutable';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import * as actionTypes from './actionTypes';

const initialState = fromJS({
  sellerConfig: fromJS({}),
  sellerList: fromJS({
    isLoading: false,
    isDirty: false,
    isLoaded: false,
    isError: false,
    idIsLoading: fromJS([]),
    value: fromJS([]),
  }),
});

function onGetSellerConfigLoading(state, { meta }) {
  const sellerId = get(meta, 'sellerId');

  if (!sellerId) {
    return state;
  }

  return state.updateIn(
    ['sellerConfig', sellerId],
    fromJS({ isLoaded: false }),
    (oldValue) => oldValue.set('isDirty', false).set('hasErrors', false),
  );
}

function onGetSellerConfigErrored(state, { meta }) {
  const sellerId = get(meta, 'sellerId');

  if (!sellerId) {
    return state;
  }

  return state.updateIn(['sellerConfig', sellerId], (oldValue) =>
    oldValue.set('isDirty', true).set('hasErrors', true),
  );
}

function onGetSellerConfigLoaded(state, { payload, meta }) {
  const sellerId = get(meta, 'sellerId');

  if (!sellerId) {
    return state;
  }

  return state.updateIn(['sellerConfig', sellerId], (oldConfig) =>
    oldConfig
      .set('isLoaded', true)
      .set('isDirty', true)
      .update('value', {}, (oldValue) => ({
        ...oldValue,
        ...payload.value,
      })),
  );
}

function onGetSellerByIdLoading(state, { meta }) {
  const sellerId = get(meta, 'sellerId');

  return state
    .setIn(['sellerList', 'isDirty'], true)
    .setIn(['sellerList', 'isError'], false)
    .setIn(['sellerList', 'isLoading'], true)
    .updateIn(['sellerList', 'idIsLoading'], (ids) => [...ids, sellerId]);
}

function onGetSellerByIdErrored(state, { meta }) {
  const sellerId = get(meta, 'sellerId');

  return state
    .setIn(['sellerList', 'isLoading'], false)
    .setIn(['sellerList', 'isLoaded'], true)
    .setIn(['sellerList', 'isError'], true)
    .updateIn(['sellerList', 'idIsLoading'], (ids) =>
      ids.filter((id) => id !== sellerId),
    );
}

function onGetSellerByIdLoaded(state, { payload, meta }) {
  const sellerId = get(meta, 'sellerId');

  return state
    .setIn(['sellerList', 'isLoading'], false)
    .setIn(['sellerList', 'isLoaded'], true)
    .updateIn(['sellerList', 'idIsLoading'], (ids) =>
      ids.filter((id) => id !== sellerId),
    )
    .updateIn(['sellerList', 'value'], (value) =>
      uniqBy([payload.value, ...value], 'id'),
    );
}

function onSetSellerConfigLoaded(state, { meta }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sellerId, indexer, ...newValue } = meta;

  if (!sellerId) {
    return state;
  }

  return state.updateIn(['sellerConfig', sellerId, 'value'], {}, (oldValue) => {
    return {
      ...oldValue,
      ...newValue,
    };
  });
}

function onSetSellerConfigAccountManagerLoaded(state, { meta }) {
  const { sellerId, ...newValue } = meta;

  if (!sellerId) {
    return state;
  }

  return state.updateIn(['sellerConfig', sellerId, 'value'], {}, (oldValue) => {
    return {
      ...oldValue,
      accountManagerConfiguration: {
        ...oldValue.accountManagerConfiguration,
        ...newValue,
      },
    };
  });
}

export default function sellerConfigurationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    // get seller configuration
    case actionTypes.GET_SELLER_CONFIGURATION.STARTED:
      return onGetSellerConfigLoading(state, action);
    case actionTypes.GET_SELLER_CONFIGURATION.ERRORED:
      return onGetSellerConfigErrored(state, action);
    case actionTypes.GET_SELLER_CONFIGURATION.SUCCEEDED:
      return onGetSellerConfigLoaded(state, action);

    case actionTypes.SET_SELLER_CONFIGURATION_OTHER.SUCCEEDED:
    case actionTypes.SET_SELLER_CONFIGURATION_TRANSPORT.SUCCEEDED:
    case actionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES.SUCCEEDED:
      return onSetSellerConfigLoaded(state, action);
    case actionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER.SUCCEEDED:
      return onSetSellerConfigAccountManagerLoaded(state, action);

    case actionTypes.GET_SELLER_BY_ID.STARTED:
      return onGetSellerByIdLoading(state, action);
    case actionTypes.GET_SELLER_BY_ID.ERRORED:
      return onGetSellerByIdErrored(state, action);
    case actionTypes.GET_SELLER_BY_ID.SUCCEEDED:
      return onGetSellerByIdLoaded(state, action);

    default:
      return state;
  }
}
