import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronLastIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon color={color} size={size}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"
      fill="currentColor"
    />
  </Icon>
);

ChevronLastIcon.displayName = 'ChevronLastIcon';

export default ChevronLastIcon;
