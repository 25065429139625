import React from 'react';

const IconDiagnosticTreeTools = () => {
  return (
    <svg
      width="80"
      height="50"
      viewBox="0 0 80 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="39.3369"
        cy="24.5664"
        rx="35"
        ry="17.5"
        fill="#205DF3"
        fillOpacity="0.05"
      />
      <path
        // eslint-disable-next-line max-len
        d="M79.2417 28.531C78.348 26.9384 76.7783 26.778 75.4492 26.0103C73.4326 24.8416 70.3619 24.6124 67.6693 25.277L42.2215 10.588C42.6339 10.0266 42.8517 9.44226 42.8517 8.85791C42.8517 8.82353 42.8517 7.34547 42.8517 7.3111C42.8173 6.32573 42.1871 6.80696 40.9497 6.09657C39.6205 5.32889 37.1686 3.71334 34.4072 3.19774C33.9718 3.11753 33.3531 2.59047 33.3187 2.80817C33.3187 2.81963 33.3187 4.26332 33.3187 4.28623C33.3187 4.37789 33.376 4.46956 33.5135 4.54976L38.5893 7.48297L32.4136 11.0464L27.3377 8.11315C27.0055 7.91837 26.444 6.62363 26.2492 6.81841C26.192 6.86424 26.2378 8.29647 26.2263 8.35376C26.2263 8.39959 26.2263 8.44543 26.2607 8.49126C27.1544 10.0839 28.6898 11.6422 30.0189 12.4098C32.0355 13.5785 35.1062 13.8077 37.7987 13.1431L63.2466 27.8321C63.0633 28.0842 62.7195 27.8779 62.6164 28.1414C62.6164 28.6341 62.5935 29.3904 62.6164 29.7685C62.6852 30.7195 63.3153 31.6246 64.5184 32.3236C65.8475 33.0912 68.5401 33.9735 71.3129 34.4891C71.7941 34.5807 72.1837 34.3516 72.1608 34.111C72.1608 34.0308 72.2639 32.6329 72.1379 32.5642L66.8902 30.9372L73.066 27.3738L78.1418 30.307C78.5772 30.559 79.2646 30.3528 79.2532 30.0434C79.2532 30.0091 79.2761 28.5654 79.2532 28.531H79.2417Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M79.2079 28.4622C78.3142 26.8696 76.7788 25.3113 75.4497 24.5437C73.4331 23.375 70.3624 23.1458 67.6698 23.8104L42.222 9.12141C43.3678 7.5746 42.9667 5.79864 40.9502 4.62994C39.6211 3.86226 36.9285 2.98001 34.1557 2.46441C33.5026 2.33837 33.0099 2.80814 33.4911 3.08313L38.5669 6.01634L32.3912 9.57972L27.3153 6.64652C26.8341 6.37153 26.0321 6.64652 26.2383 7.02462C27.132 8.61726 28.6674 10.1755 29.9965 10.9432C32.0131 12.1119 35.0838 12.3411 37.7763 11.6765L63.2242 26.3655C62.0784 27.9123 62.4794 29.6882 64.496 30.8569C65.8251 31.6246 68.5177 32.5069 71.2905 33.0225C71.9436 33.1485 72.4363 32.6787 71.9551 32.4037L66.8792 29.4705L73.055 25.9071L78.1308 28.8403C78.6121 29.1153 79.4141 28.8403 79.2079 28.4622Z"
        fill="#B9C0E0"
      />
      <path
        // eslint-disable-next-line max-len
        d="M41.0762 12.3755L43.425 11.0234L64.5533 23.2146L62.2044 24.5781L41.0762 12.3755Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M41.5 12.6156L62.2043 24.5776L64.1292 23.4661L43.4249 11.5156L41.5 12.6156Z"
        fill="#949EC9"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.2706 0.321449C4.76646 0.20687 4.04462 0.10375 3.17382 0.195412C2.89883 0.218328 2.58947 0.321449 2.26865 0.504774C1.01975 1.22662 0 2.99112 0 4.43481C0 4.80146 0.068747 5.11082 0.183325 5.35144C0.183325 5.3629 0.515603 6.41702 1.54681 7.27635C1.29474 6.97845 1.14578 6.55451 1.09995 6.03891C1.44369 6.08474 1.85617 5.99308 2.29157 5.741C3.55193 5.01916 4.56022 3.25465 4.56022 1.79951C4.56022 1.27245 4.42272 0.859967 4.19357 0.584979C4.58313 0.390196 4.94979 0.298533 5.28206 0.309991L5.2706 0.321449Z"
        fill="#1556BB"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.04959 0.573361C6.04959 0.573361 5.76314 0.435867 5.27045 0.321289C4.88089 0.321289 4.43403 0.435867 3.96426 0.710855C2.36017 1.63894 1.06543 3.88468 1.06543 5.72939C1.06543 6.4054 1.2373 6.93246 1.5352 7.28765C1.66124 7.39077 1.79873 7.49389 1.93623 7.59701C2.39454 7.89492 18.8136 15.9841 18.8136 15.9841L21.6437 10.8969L6.04959 0.573361Z"
        fill="#1A6EEF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M22.2283 12.2264C22.2283 13.5441 21.3117 15.1367 20.1659 15.7898C19.0316 16.4429 18.1035 15.9159 18.1035 14.5982C18.1035 13.2806 19.0201 11.6879 20.1659 11.0348C21.3003 10.3817 22.2283 10.9088 22.2283 12.2264Z"
        fill="#6C9FFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M48.8897 28.6679L20.8524 12.4436C20.7035 12.3405 20.4858 12.3519 20.2566 12.4894C19.7983 12.753 19.4316 13.3946 19.4316 13.9217L47.9273 30.4095L48.8897 28.6679Z"
        fill="#949EC9"
      />
      <path
        // eslint-disable-next-line max-len
        d="M19.4316 13.9102C19.4316 14.2081 19.5462 14.3914 19.7295 14.4601L47.7669 30.6959L47.9273 30.4094L19.4316 13.9102Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M51.2628 30.7418V30.547C50.7586 30.1116 49.0629 28.8398 49.0171 28.7711C48.8681 28.5878 48.616 28.5534 48.3181 28.7253C47.8598 28.9888 47.4932 29.6304 47.4932 30.1575C47.4932 30.1575 47.4932 30.1575 47.4932 30.1689C47.4932 30.4439 47.5963 30.6273 47.7681 30.696C47.9171 30.7647 50.1399 31.3835 50.1399 31.3835L51.2628 30.7304V30.7418Z"
        fill="#B9C0E0"
      />
      <path
        // eslint-disable-next-line max-len
        d="M51.2625 30.7417L50.1396 31.3948V31.2L51.2625 30.5469V30.7417Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M47.4932 30.1689C47.4932 30.4439 47.5963 30.6273 47.7681 30.696L50.1399 31.3835V31.1887L47.4932 30.1689Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M19.4786 10.3817C17.2557 8.70885 6.32496 1.07794 5.75207 0.974815C4.86982 0.814405 3.74695 1.9029 3.62091 2.88827C3.49488 3.87365 18.413 11.2983 18.413 11.2983C18.413 11.2983 18.8025 10.5879 19.4786 10.3817Z"
        fill="#0D42A1"
      />
      <path
        // eslint-disable-next-line max-len
        d="M17.4276 13.8992C14.8725 12.7992 2.76152 7.29946 2.38341 6.85261C1.79906 6.16514 1.97093 4.64125 2.77298 4.04544C3.57503 3.44963 17.9546 12.2836 17.9546 12.2836C17.9546 12.2836 17.3015 13.0628 17.4276 13.8877V13.8992Z"
        fill="#0D42A1"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.989 22.0459C9.86611 22.6989 9.23593 22.3094 9.13281 23.1573C9.13281 23.2489 9.13281 24.5437 9.13281 24.6353C9.13281 25.5749 9.75154 26.5144 10.989 27.2248C13.4639 28.657 17.4741 28.657 19.9605 27.2248C21.1979 26.5144 21.8166 25.5749 21.8166 24.6353C21.8166 24.6009 21.8166 23.1458 21.8166 23.1229C21.45 22.4125 21.1635 22.7448 19.9605 22.0459C17.4856 20.6136 13.4753 20.6136 10.989 22.0459ZM19.1011 25.1968L16.4429 26.7321L12.8108 26.1707L11.8369 24.0739L14.4951 22.5385L18.1272 23.1L19.1011 25.1968Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.989 20.6132C8.51409 22.0455 8.51409 24.3599 10.989 25.7922C13.4639 27.2244 17.4741 27.2244 19.9605 25.7922C22.4468 24.3599 22.4354 22.0455 19.9605 20.6132C17.4856 19.181 13.4753 19.181 10.989 20.6132ZM19.1011 23.7641L16.4429 25.2995L12.8108 24.7381L11.8369 22.6413L14.4951 21.1059L18.1272 21.6674L19.1011 23.7641Z"
        fill="#B9C0E0"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16.9473 28.1635L47.4366 45.7742L51.8707 43.2076L21.3814 25.6084L16.9473 28.1635Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16.9473 26.6977L47.4366 44.3084L51.8707 41.7418L21.3814 24.1426L16.9473 26.6977Z"
        fill="#B9C0E0"
      />
      <path
        // eslint-disable-next-line max-len
        d="M20.2354 27.3967L22.5842 26.0332L48.7539 41.1575L46.405 42.5096L20.2354 27.3967Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M20.6592 27.6371L46.4049 42.5093L48.3299 41.3979L22.5841 26.5371L20.6592 27.6371Z"
        fill="#949EC9"
      />
      <path
        // eslint-disable-next-line max-len
        d="M48.364 43.5869C47.2411 44.24 46.6109 43.8504 46.5078 44.6983C46.5078 44.7899 46.5078 46.0847 46.5078 46.1763C46.5078 47.1159 47.1265 48.0554 48.364 48.7658C50.8389 50.198 54.8491 50.198 57.3355 48.7658C58.5729 48.0554 59.1916 47.1159 59.1916 46.1763C59.1916 46.142 59.1916 44.6868 59.1916 44.6639C58.825 43.9535 58.5385 44.2858 57.3355 43.5869C54.8606 42.1546 50.8503 42.1546 48.364 43.5869ZM56.4761 46.7378L53.8179 48.2731L50.1858 47.7117L49.2119 45.6149L51.8701 44.0796L55.5022 44.641L56.4761 46.7378Z"
        fill="#747DA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M48.364 42.166C45.8891 43.5982 45.8891 45.9127 48.364 47.3449C50.8389 48.7771 54.8491 48.7771 57.3355 47.3449C59.8218 45.9127 59.8104 43.5982 57.3355 42.166C54.8606 40.7337 50.8503 40.7337 48.364 42.166ZM56.4761 45.3169L53.8179 46.8522L50.1858 46.2908L49.2119 44.194L51.8701 42.6587L55.5022 43.2201L56.4761 45.3169Z"
        fill="#B9C0E0"
      />
    </svg>
  );
};

IconDiagnosticTreeTools.displayName = 'IconDiagnosticTreeTools';

export default IconDiagnosticTreeTools;
