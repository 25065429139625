import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';

// eslint-disable-next-line react/display-name
function DefaultOptionFormatter<Option extends BaseAutocompleteOption>({
  label,
}: Option) {
  return SafeFormattedMessage(label);
}

export default DefaultOptionFormatter;
