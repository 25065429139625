import { useMutation } from 'react-query';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { PaymentService } from '../../../../../../api';

export const useConfirmPaymentIntent = ({
  onSuccess,
  invoiceId,
  claimGroupId,
  fileId,
}: {
  invoiceId: string;
  onSuccess?: () => void;
  claimGroupId?: string;
  fileId?: string;
}) => {
  const { pushErrors } = useToasts();

  const { mutateAsync } = useMutation(
    'confirmPaymentIntent',
    async () => {
      const response = await PaymentService.confirmPaymentIntent({ invoiceId });

      if (response.failure) {
        throw new Error(response.errors[0].message);
      }

      if (onSuccess) {
        onSuccess();
      }

      return response;
    },
    {
      retry: 5,
      retryDelay: 2_500,
      onError: (error: any) => {
        pushErrors(error.message);

        logCritical(
          `InvoicePaymentForm.handleSubmit: invoiceId => ${invoiceId}, claimGroupId => ${claimGroupId}, fileId => ${fileId}`,
          {
            invoiceId,
            error,
            claimGroupId,
            fileId,
            errorMessage: error.message,
          },
        );
      },
    },
  );

  return { handleConfirmPaymentIntent: mutateAsync };
};
