import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/summary`;

export interface ClaimContextSummary {
  fileNumber?: string;
  templateName?: string;
  headerMessage?: string;
  contentTitle?: string;
  content1?: string;
  content2?: string;
  documents?: {
    type: string;
    url?: string;
  }[];
  issueId: string;
  issueName?: string;
  reasonName?: string;
  solutionName?: string;
  fileId?: string;
  validationTemplateName?: string;
  validationHeaderMessage?: string;
  validationContentTitle?: string;
  validationContent1?: string;
  validationContent2?: string;
  originPathway: string;
}

interface GetClaimContextSummaryQueryArgs {
  claimId: string;
}

const getClaimContextSummaryQuery = async ({
  claimId,
}: GetClaimContextSummaryQueryArgs): Promise<
  BackResponse<ClaimContextSummary> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<BackResponse<ClaimContextSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimContextSummaryQuery;
