import React from 'react';

interface AddDatabaseScriptIconProps {
  color?: string;
}

/* eslint-disable max-len */
const AddDatabaseScriptIcon: React.FC<AddDatabaseScriptIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M36.6666 23.3333V14.1667M9.99992 21.6667V10C9.99992 8.67392 10.5267 7.40215 11.4644 6.46447C12.4021 5.52679 13.6738 5 14.9999 5H23.3333M28.3199 6.66667H33.3199M38.3199 6.66667H33.3199M33.3199 6.66667V1.66667M33.3199 6.66667V11.6667M19.9999 35H9.99992C8.23181 35 6.53612 34.2976 5.28587 33.0474C4.03563 31.7971 3.33325 30.1014 3.33325 28.3333C3.33325 26.5652 4.03563 24.8695 5.28587 23.6193C6.53612 22.369 8.23181 21.6667 9.99992 21.6667H29.9999C28.6814 21.6667 27.3924 22.0577 26.2961 22.7902C25.1998 23.5227 24.3453 24.5639 23.8407 25.7821C23.3361 27.0003 23.2041 28.3407 23.4613 29.6339C23.7186 30.9271 24.3535 32.115 25.2859 33.0474C26.2182 33.9797 27.4061 34.6147 28.6993 34.8719C29.9925 35.1291 31.333 34.9971 32.5511 34.4925C33.7693 33.9879 34.8105 33.1335 35.543 32.0371C36.2756 30.9408 36.6666 29.6519 36.6666 28.3333V23.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AddDatabaseScriptIcon.displayName = 'AddDatabaseScriptIcon';

export default AddDatabaseScriptIcon;
