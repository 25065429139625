import React from 'react';

interface PortraitIconProps {
  color?: string;
}

/* eslint-disable max-len */
const PortraitIcon: React.FC<PortraitIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M18.3333 0.25H1.66667C0.7475 0.25 0 0.9975 0 1.91667V18.5833C0 19.5025 0.7475 20.25 1.66667 20.25H18.3333C19.2525 20.25 20 19.5025 20 18.5833V1.91667C20 0.9975 19.2525 0.25 18.3333 0.25ZM18.3333 18.5833H1.66667L1.66417 1.91667C1.66417 1.91667 1.66417 1.91667 1.66667 1.91667H18.3333V18.5833Z"
        fill="currentColor"
      />
      <path
        d="M2.91667 14.4167H17.0833C17.3125 14.4167 17.5 14.2292 17.5 14V3.16667C17.5 2.9375 17.3125 2.75 17.0833 2.75H2.91667C2.6875 2.75 2.5 2.9375 2.5 3.16667V14C2.5 14.2292 2.6875 14.4167 2.91667 14.4167ZM12.9092 13.5833H7.09167C7.89667 12.5367 8.95667 11.9167 10.0008 11.9167C11.045 11.9167 12.1042 12.5367 12.9092 13.5833ZM3.33333 3.58333H16.6667V13.5833H14.8883C14.3725 12.6325 13.68 11.8258 12.8833 11.245C12.5133 10.9767 12.1283 10.7583 11.73 10.5967C12.6908 10.0108 13.3342 8.95333 13.3342 7.75C13.3342 5.91167 11.8392 4.41667 10.0008 4.41667C8.1625 4.41667 6.6675 5.91167 6.6675 7.75C6.6675 8.95333 7.31083 10.01 8.26917 10.5967C7.87083 10.7583 7.485 10.9742 7.11583 11.245C6.32167 11.8258 5.62917 12.6333 5.11083 13.5833H3.335V3.58333H3.33333ZM10 10.25C8.6225 10.25 7.5 9.1275 7.5 7.75C7.5 6.3725 8.6225 5.25 10 5.25C11.3775 5.25 12.5 6.3725 12.5 7.75C12.5 9.1275 11.3775 10.25 10 10.25Z"
        fill="currentColor"
      />
      <path d="M10 16.0833H16.6667V16.9167H10V16.0833Z" fill="currentColor" />
    </svg>
  );
};

PortraitIcon.displayName = 'PortraitIcon';

export default PortraitIcon;
