import React from 'react';

interface SofaIconProps {
  color?: string;
}

/* eslint-disable max-len */
const SofaIcon: React.FC<SofaIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.5C11.337 11.5 10.7011 11.7634 10.2322 12.2322C9.76339 12.7011 9.5 13.337 9.5 14V18C9.5 18.8284 8.82843 19.5 8 19.5C7.17157 19.5 6.5 18.8284 6.5 18V14C6.5 12.5413 7.07946 11.1424 8.11091 10.1109C9.14236 9.07946 10.5413 8.5 12 8.5H36C37.4587 8.5 38.8576 9.07946 39.8891 10.1109C40.9205 11.1424 41.5 12.5413 41.5 14V18C41.5 18.8284 40.8284 19.5 40 19.5C39.1716 19.5 38.5 18.8284 38.5 18V14C38.5 13.337 38.2366 12.7011 37.7678 12.2322C37.2989 11.7634 36.663 11.5 36 11.5H12ZM4 30.5C4.82843 30.5 5.5 31.1716 5.5 32V38C5.5 38.8284 4.82843 39.5 4 39.5C3.17157 39.5 2.5 38.8284 2.5 38V32C2.5 31.1716 3.17157 30.5 4 30.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11091 18.1109C5.14236 17.0795 6.54131 16.5 8 16.5C9.45869 16.5 10.8576 17.0795 11.8891 18.1109C12.9205 19.1424 13.5 20.5413 13.5 22V24.5H34.5V22C34.5 20.5413 35.0795 19.1424 36.1109 18.1109C37.1424 17.0795 38.5413 16.5 40 16.5C41.4587 16.5 42.8576 17.0795 43.8891 18.1109C44.9205 19.1424 45.5 20.5413 45.5 22V38C45.5 38.8284 44.8284 39.5 44 39.5C43.1716 39.5 42.5 38.8284 42.5 38V35.5H4C3.17157 35.5 2.5 34.8284 2.5 34V22C2.5 20.5413 3.07946 19.1424 4.11091 18.1109ZM42.5 32.5V22C42.5 21.337 42.2366 20.7011 41.7678 20.2322C41.2989 19.7634 40.663 19.5 40 19.5C39.337 19.5 38.7011 19.7634 38.2322 20.2322C37.7634 20.7011 37.5 21.337 37.5 22V26C37.5 26.8284 36.8284 27.5 36 27.5H12C11.1716 27.5 10.5 26.8284 10.5 26V22C10.5 21.337 10.2366 20.7011 9.76777 20.2322C9.29893 19.7634 8.66304 19.5 8 19.5C7.33696 19.5 6.70107 19.7634 6.23223 20.2322C5.76339 20.7011 5.5 21.337 5.5 22V32.5H42.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

SofaIcon.displayName = 'SofaIcon';

export default SofaIcon;
