import React, { FunctionComponent, MutableRefObject } from 'react';
import { UseFormRegister, UseFormTrigger } from 'react-hook-form';

import Input from '../../../atoms/Form/Input';
import { InputProps } from '../../../atoms/Form/Input/Input.types';

function mergeRefs(
  refs: Array<
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null
  >,
): React.RefCallback<HTMLInputElement | null> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}

interface InputHookFormProps extends Exclude<InputProps, 'onChange'> {
  register: UseFormRegister<any>;
  trigger?: UseFormTrigger<any>;
  name: string;
}
interface InputHookFormPWithRef extends InputHookFormProps {
  forwardedRef:
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null;
}

const InputHookForm: FunctionComponent<
  React.PropsWithChildren<InputHookFormPWithRef>
> = ({ register, name, validatingRegex, trigger, forwardedRef, ...rest }) => {
  const { ref, onChange, ...resgisteredInput } = register(name);

  return (
    <Input
      {...rest}
      {...resgisteredInput}
      onChange={(event) => {
        if (validatingRegex && event.target.value && trigger) {
          trigger(name);
        }

        return onChange(event);
      }}
      ref={mergeRefs([ref, forwardedRef])}
    />
  );
};

InputHookForm.displayName = 'InputHookForm';

export default React.forwardRef<HTMLInputElement, InputHookFormProps>(
  (props, ref) => <InputHookForm forwardedRef={ref} {...props} />,
);
