import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { PaymentService } from 'myaccount/api';

const CreatePaymentIntent = 'createPaymentIntent';

const useCreatePaymentIntent = ({ invoiceId }: { invoiceId?: string }) => {
  const { pushErrors } = useToasts();

  const { data: paymentIntentClientSecret, isLoading } = useQuery(
    [CreatePaymentIntent, { invoiceId }],
    async () => {
      if (!invoiceId) {
        return undefined;
      }

      const response = await PaymentService.createPaymentIntent({ invoiceId });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    paymentIntentClientSecret,
    isLoading,
  };
};

export default useCreatePaymentIntent;
