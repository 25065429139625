import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
  CustomerFileSummary,
} from '@savgroup-front-common/types';
import { apiCall } from '../../services';
import { prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/shortInfo`;

interface GetShortFileInfoQueryPayload {
  fileId: string;
}

type GetShortFileInfoQuery = (
  payload: GetShortFileInfoQueryPayload,
) => Promise<BackResponse<CustomerFileSummary> | BackResponseFailure>;

export const getShortFileInfoQuery: GetShortFileInfoQuery = async ({
  fileId,
}) => {
  try {
    return await apiCall<BackResponse<CustomerFileSummary>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
