import React from 'react';

interface RefreshDoubleIconProps {
  color?: string;
}

/* eslint-disable max-len */
const RefreshDoubleIcon: React.FC<RefreshDoubleIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M35.2801 13.3333C33.982 10.3608 31.8451 7.8314 29.131 6.05519C26.417 4.27897 23.2437 3.33305 20.0001 3.33334C11.3584 3.33334 4.25008 9.91167 3.41675 18.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3334 13.3333H35.6668C35.7981 13.3333 35.9281 13.3075 36.0494 13.2572C36.1708 13.207 36.281 13.1333 36.3739 13.0404C36.4667 12.9476 36.5404 12.8373 36.5906 12.716C36.6409 12.5947 36.6668 12.4647 36.6668 12.3333V5M4.80176 26.6667C7.37509 32.5533 13.2484 36.6667 20.0818 36.6667C28.7251 36.6667 35.8318 30.0883 36.6668 21.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7501 26.6667H4.41675C4.15153 26.6667 3.89718 26.772 3.70964 26.9596C3.5221 27.1471 3.41675 27.4015 3.41675 27.6667V35"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

RefreshDoubleIcon.displayName = 'RefreshDoubleIcon';

export default RefreshDoubleIcon;
