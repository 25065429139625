import 'intl';
import throttle from 'lodash/throttle';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { appShell } from '@savgroup-front-common/core/src/helpers';
import 'myaccount/helpers/localMoment';
import configureStoreAndHistory from 'myaccount/store/configureStore';
import { saveState } from 'myaccount/store/localStorage';
import 'myaccount/view/wdyr';

import '../datadog.config';

import { ControlUserManagerConfig } from './helpers';
import App from './view/app/App';

if (![].at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

ControlUserManagerConfig.getCacheAsync().then(
  ({ userManager, userManagerConfig, resetPassword }) => {
    const { store, history } = configureStoreAndHistory({
      userManager,
    });

    store.subscribe(() => {
      throttle(() => {
        saveState(store.getState());
      }, 1000);
    });

    appShell({
      userManager,
      render: () => {
        const container = document.getElementById('root');
        const root = createRoot(container);

        root.render(
          <App
            store={store}
            resetPassword={resetPassword}
            history={history}
            userManagerConfig={userManagerConfig}
            userManager={userManager}
          />,
        );
      },
    })
      // eslint-disable-next-line no-console
      .then(() => console.info('App launched with success.'))
      // eslint-disable-next-line no-console
      .catch((e) => console.error('App failed to launch.', e));
  },
);
