import React from 'react';

interface PeopleGroupIconProps {
  color?: string;
}

/* eslint-disable max-len */
const PeopleGroupIcon: React.FC<PeopleGroupIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M1.66675 33.3333V31.6667C1.66675 28.5725 2.89591 25.605 5.08384 23.4171C7.27176 21.2292 10.2392 20 13.3334 20V20C16.4276 20 19.3951 21.2292 21.583 23.4171C23.7709 25.605 25.0001 28.5725 25.0001 31.6667V33.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.6667 23.3333C21.6667 21.1232 22.5447 19.0036 24.1075 17.4408C25.6703 15.878 27.7899 15 30.0001 15V15C31.0944 15 32.1781 15.2155 33.1891 15.6343C34.2002 16.0531 35.1188 16.667 35.8926 17.4408C36.6665 18.2146 37.2803 19.1333 37.6991 20.1443C38.1179 21.1554 38.3334 22.239 38.3334 23.3333V24.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.3334 20C15.1015 20 16.7972 19.2976 18.0475 18.0474C19.2977 16.7971 20.0001 15.1014 20.0001 13.3333C20.0001 11.5652 19.2977 9.86953 18.0475 8.61929C16.7972 7.36905 15.1015 6.66667 13.3334 6.66667C11.5653 6.66667 9.86961 7.36905 8.61937 8.61929C7.36913 9.86953 6.66675 11.5652 6.66675 13.3333C6.66675 15.1014 7.36913 16.7971 8.61937 18.0474C9.86961 19.2976 11.5653 20 13.3334 20V20ZM30.0001 15C31.3262 15 32.5979 14.4732 33.5356 13.5355C34.4733 12.5979 35.0001 11.3261 35.0001 10C35.0001 8.67392 34.4733 7.40215 33.5356 6.46447C32.5979 5.52678 31.3262 5 30.0001 5C28.674 5 27.4022 5.52678 26.4645 6.46447C25.5269 7.40215 25.0001 8.67392 25.0001 10C25.0001 11.3261 25.5269 12.5979 26.4645 13.5355C27.4022 14.4732 28.674 15 30.0001 15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PeopleGroupIcon.displayName = 'PeopleGroupIcon';

export default PeopleGroupIcon;
