/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { buildPageTitle } from '../../helpers';

import ErrorBoundyTemplate from './ErrorBoundyTemplate';
import messages from './messages';

class ComponentErrorBoundary extends React.Component<
  { home?: string; children: ReactNode },
  { hasError: boolean }
> {
  constructor(props: { home?: string; children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });

    this.setState({ hasError: true });

    logCritical(error, { errorInfo });

    document.title = buildPageTitle([messages.title]);
  }

  render() {
    const { children, home } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorBoundyTemplate home={home} />;
    }

    return children;
  }
}

export default ComponentErrorBoundary;
