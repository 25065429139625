import React from 'react';

interface GridRemoveIconProps {
  color?: string;
}

/* eslint-disable max-len */
const GridRemoveIcon: React.FC<GridRemoveIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M24.7852 31.8683L28.3218 28.3333M31.8568 24.7983L28.3218 28.3333M28.3218 28.3333L24.7852 24.7983M28.3218 28.3333L31.8568 31.8683"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 15.6667V7.66666C6.66675 7.40145 6.7721 7.14709 6.95964 6.95956C7.14718 6.77202 7.40153 6.66666 7.66675 6.66666H15.6667C15.932 6.66666 16.1863 6.77202 16.3739 6.95956C16.5614 7.14709 16.6667 7.40145 16.6667 7.66666V15.6667C16.6667 15.9319 16.5614 16.1862 16.3739 16.3738C16.1863 16.5613 15.932 16.6667 15.6667 16.6667H7.66675C7.40153 16.6667 7.14718 16.5613 6.95964 16.3738C6.7721 16.1862 6.66675 15.9319 6.66675 15.6667V15.6667ZM6.66675 32.3333V24.3333C6.66675 24.0681 6.7721 23.8138 6.95964 23.6262C7.14718 23.4387 7.40153 23.3333 7.66675 23.3333H15.6667C15.932 23.3333 16.1863 23.4387 16.3739 23.6262C16.5614 23.8138 16.6667 24.0681 16.6667 24.3333V32.3333C16.6667 32.5985 16.5614 32.8529 16.3739 33.0404C16.1863 33.228 15.932 33.3333 15.6667 33.3333H7.66675C7.40153 33.3333 7.14718 33.228 6.95964 33.0404C6.7721 32.8529 6.66675 32.5985 6.66675 32.3333V32.3333ZM23.3334 15.6667V7.66666C23.3334 7.40145 23.4388 7.14709 23.6263 6.95956C23.8138 6.77202 24.0682 6.66666 24.3334 6.66666H32.3334C32.5986 6.66666 32.853 6.77202 33.0405 6.95956C33.2281 7.14709 33.3334 7.40145 33.3334 7.66666V15.6667C33.3334 15.9319 33.2281 16.1862 33.0405 16.3738C32.853 16.5613 32.5986 16.6667 32.3334 16.6667H24.3334C24.0682 16.6667 23.8138 16.5613 23.6263 16.3738C23.4388 16.1862 23.3334 15.9319 23.3334 15.6667V15.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

GridRemoveIcon.displayName = 'GridRemoveIcon';

export default GridRemoveIcon;
