import React, { FC, Suspense, useContext } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import {
  StepContext,
  StepContextValues,
} from '../../../app/NewLayout/StepsProvider/StepsProvider.context';
import { Payment } from '../../../components/Payments/Payment';
import { $Heading, $HeadingIcon } from '../ClaimGroupConfirmationPage.styles';

import useGetPaymentStatus from './hooks/useGetPaymentStatus';
import { usePaymentCard } from './hooks/usePaymentCard';
import messages from './messages';

interface PaymentCardProps {
  sellerId: string;
  onSubmitSuccess?: () => void;
  isFormCompleted?: boolean;
}

const PaymentCard: FC<PaymentCardProps> = ({
  sellerId,
  onSubmitSuccess = () => undefined,
  isFormCompleted = true,
}) => {
  const { paymentUrl, invoice } = usePaymentCard({ sellerId });

  const { values } = useContext<StepContextValues>(StepContext);
  const { claimGroup } = values;
  const claimGroupId = claimGroup?.claimGroupId;

  const paymentStatus = useGetPaymentStatus({
    paymentUrl,
    claimGroupId,
  });

  return (
    <Suspense fallback={<BaseLoader />}>
      <$Heading>
        <$HeadingIcon>
          <MyAccountIcon icon={ICONS_TYPE.CART_ICON} />
        </$HeadingIcon>
        <SafeFormattedMessageWithoutSpread message={messages.paymentTitle} />
      </$Heading>

      {invoice && claimGroupId && (
        <Payment
          invoice={{
            id: invoice.id,
            priceWithTax: invoice.priceWithTax,
            currencyTrigram:
              invoice.item?.at(0)?.currencyCode ?? CURRENCIES.EUR,
          }}
          paymentUrl={paymentUrl}
          onSubmitSuccess={onSubmitSuccess}
          isFormCompleted={isFormCompleted}
          paymentStatus={paymentStatus}
          claimGroupId={claimGroupId}
        />
      )}
    </Suspense>
  );
};

PaymentCard.displayName = 'PaymentCard';

export default PaymentCard;
