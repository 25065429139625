import React, { FormEvent, FormEventHandler, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { MessageType } from '../../../../../types';
import { safeFormattedIntlString } from '../../../formatters';

import { $Form } from './Form.styles';
import messages from './messages';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface FormProps<T extends FieldValues> {
  onChange?: (event: FormEvent<EventTarget | HTMLFormElement>) => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  hasNoRequiredMarked?: boolean;
  recommendMessage?: string | number | MessageType | MessageDescriptor;
  formContext: UseFormReturn<T>;
  children: ReactNode;
}

function Form<T extends FieldValues>({
  children,
  onChange,
  onSubmit,
  hasNoRequiredMarked = false,
  recommendMessage,
  formContext,
}: FormProps<T>) {
  const optionnalMessage = safeFormattedIntlString(
    recommendMessage || messages.optionnalMessage,
  );

  return (
    <FormProvider {...formContext}>
      <$Form
        onChange={onChange}
        onSubmit={onSubmit}
        $hasNoRequiredMarked={hasNoRequiredMarked}
        $optionnalMessage={optionnalMessage}
      >
        {children}
      </$Form>
    </FormProvider>
  );
}

Form.displayName = 'Form';

export default Form;
