import PropTypes from 'prop-types';
import React, { Component } from 'react';

import defaultComponentDecorator from '../decorators/defaultComponentDecorator';
import defaultHrefDecorator from '../decorators/defaultHrefDecorator';
import defaultMatchDecorator from '../decorators/defaultMatchDecorator';
import defaultTextDecorator from '../decorators/defaultTextDecorator';

class Linkify extends Component {
  parseString(string) {
    if (string === '') {
      return string;
    }

    const { matchDecorator, hrefDecorator, textDecorator, componentDecorator } =
      this.props;

    const matches = matchDecorator(string);

    if (!matches) {
      return string;
    }

    const elements = [];

    let lastIndex = 0;

    matches.forEach((match, i) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }

      const decoratedHref = hrefDecorator(match.url);
      const decoratedText = textDecorator(match.text);
      const decoratedComponent = componentDecorator(
        decoratedHref,
        decoratedText,
        i,
      );

      elements.push(decoratedComponent);

      // eslint-disable-next-line prefer-destructuring
      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  parse(children, key = 0) {
    let result = children;

    if (typeof children === 'string') {
      result = this.parseString(children);
    } else if (
      React.isValidElement(children) &&
      children.type !== 'a' &&
      children.type !== 'button'
    ) {
      result = React.cloneElement(
        children,
        { key },
        this.parse(children.props.children),
      );
    } else if (Array.isArray(children)) {
      result = children.map((child, i) => this.parse(child, i));
    }

    return result;
  }

  render() {
    const { children } = this.props;

    return <>{this.parse(children)}</>;
  }
}

Linkify.propTypes = {
  children: PropTypes.node.isRequired,
  componentDecorator: PropTypes.func,
  hrefDecorator: PropTypes.func,
  matchDecorator: PropTypes.func,
  textDecorator: PropTypes.func,
};

Linkify.defaultProps = {
  componentDecorator: defaultComponentDecorator,
  hrefDecorator: defaultHrefDecorator,
  matchDecorator: defaultMatchDecorator,
  textDecorator: defaultTextDecorator,
};

export default Linkify;
