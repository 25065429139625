import React from 'react';

interface DeliveryTruckIconProps {
  color?: string;
}

/* eslint-disable max-len */
const DeliveryTruckIcon: React.FC<DeliveryTruckIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M13.3333 31.6667C14.2174 31.6667 15.0652 31.3155 15.6904 30.6904C16.3155 30.0652 16.6667 29.2174 16.6667 28.3333C16.6667 27.4493 16.3155 26.6014 15.6904 25.9763C15.0652 25.3512 14.2174 25 13.3333 25C12.4493 25 11.6014 25.3512 10.9763 25.9763C10.3512 26.6014 10 27.4493 10 28.3333C10 29.2174 10.3512 30.0652 10.9763 30.6904C11.6014 31.3155 12.4493 31.6667 13.3333 31.6667V31.6667ZM30 31.6667C30.8841 31.6667 31.7319 31.3155 32.357 30.6904C32.9821 30.0652 33.3333 29.2174 33.3333 28.3333C33.3333 27.4493 32.9821 26.6014 32.357 25.9763C31.7319 25.3512 30.8841 25 30 25C29.1159 25 28.2681 25.3512 27.643 25.9763C27.0179 26.6014 26.6667 27.4493 26.6667 28.3333C26.6667 29.2174 27.0179 30.0652 27.643 30.6904C28.2681 31.3155 29.1159 31.6667 30 31.6667V31.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7501 28.3333H25.0001V11C25.0001 10.7348 24.8947 10.4804 24.7072 10.2929C24.5197 10.1054 24.2653 10 24.0001 10H1.66675M9.41675 28.3333H6.00008C5.86876 28.3333 5.73872 28.3075 5.6174 28.2572C5.49607 28.207 5.38583 28.1333 5.29297 28.0404C5.20012 27.9476 5.12646 27.8373 5.0762 27.716C5.02595 27.5947 5.00008 27.4647 5.00008 27.3333V19.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.3335 15H10.0002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 15H34.35C34.5433 15 34.7324 15.0561 34.8945 15.1614C35.0566 15.2667 35.1847 15.4167 35.2633 15.5933L38.2467 22.3067C38.3035 22.4341 38.333 22.5721 38.3333 22.7117V27.3333C38.3333 27.4647 38.3075 27.5947 38.2572 27.716C38.207 27.8373 38.1333 27.9476 38.0404 28.0404C37.9476 28.1333 37.8373 28.207 37.716 28.2572C37.5947 28.3075 37.4647 28.3333 37.3333 28.3333H34.1667M25 28.3333H26.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

DeliveryTruckIcon.displayName = 'DeliveryTruckIcon';

export default DeliveryTruckIcon;
