import React, { FunctionComponent, MutableRefObject } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { RadioButton } from '../../../atoms/Form/Radio';
import { RadioProps } from '../../../atoms/Form/Radio/RadioButton/RadioButton';

function mergeRefs(
  refs: Array<
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null
  >,
): React.RefCallback<HTMLInputElement | null> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}

interface RadioButtonHookFormProps extends Exclude<RadioProps, 'onChange'> {
  register: UseFormRegister<any>;
  name: string;
}
interface InputHookFormPWithRef extends RadioButtonHookFormProps {
  forwardedRef:
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null;
}

const RadioButtonHookForm: FunctionComponent<React.PropsWithChildren<InputHookFormPWithRef>> = ({
  register,
  name,
  forwardedRef,
  ...rest
}) => {
  const { ref, ...resgisteredInput } = register(name);

  return (
    <RadioButton
      {...rest}
      {...resgisteredInput}
      ref={mergeRefs([ref, forwardedRef])}
    />
  );
};

RadioButtonHookForm.displayName = 'RadioButtonHookForm';

export default React.forwardRef<HTMLInputElement, RadioButtonHookFormProps>(
  (props, ref) => <RadioButtonHookForm forwardedRef={ref} {...props} />,
);
