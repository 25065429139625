import React, { FC, Suspense } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { Invoice } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { Payment } from '../../../../../../components/Payments/Payment';

import useGetPaymentStatus from './hooks/useGetPaymentStatus';
import { usePaymentCard } from './hooks/usePaymentCard';
import messages from './messages';
import { $Heading, $HeadingIcon } from './PaymentCard.styles';

interface PaymentCardProps {
  invoice: Invoice;
  sellerId: string;
  claimGroupId: string;
  fileId: string;
  onSubmitSuccess?: () => void;
  isFormCompleted?: boolean;
}

export const PaymentCard: FC<PaymentCardProps> = ({
  invoice,
  sellerId,
  claimGroupId,
  fileId,
  onSubmitSuccess = () => undefined,
  isFormCompleted = true,
}) => {
  const { paymentUrl } = usePaymentCard({ sellerId });

  const paymentStatus = useGetPaymentStatus({
    fileId,
    claimGroupId,
    paymentUrl,
  });

  return (
    <Suspense fallback={<BaseLoader />}>
      <$Heading>
        <$HeadingIcon>
          <MyAccountIcon icon={ICONS_TYPE.CART_ICON} />
        </$HeadingIcon>
        <SafeFormattedMessageWithoutSpread message={messages.paymentTitle} />
      </$Heading>

      <Payment
        invoice={{
          id: invoice.id,
          priceWithTax: invoice.priceWithTax,
          currencyTrigram: invoice.currencyTrigram,
        }}
        paymentUrl={paymentUrl}
        onSubmitSuccess={onSubmitSuccess}
        isFormCompleted={isFormCompleted}
        paymentStatus={paymentStatus}
        claimGroupId={claimGroupId}
      />
    </Suspense>
  );
};

PaymentCard.displayName = 'PaymentCard';
