/* eslint-disable max-len, react/display-name, @typescript-eslint/no-unused-vars, react/destructuring-assignment, react/state-in-constructor */
import React, { ErrorInfo, ReactNode } from 'react';
import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

interface Props {
  children?: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logVerbose(`Uncaught error: ${error}`, errorInfo as any);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
