/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronFastRightIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon color={color} size={size} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M3.72481 4.55806C3.96888 4.31398 4.36461 4.31398 4.60869 4.55806L9.60869 9.55806C9.85277 9.80214 9.85277 10.1979 9.60869 10.4419L4.60869 15.4419C4.36461 15.686 3.96888 15.686 3.72481 15.4419C3.48073 15.1979 3.48073 14.8021 3.72481 14.5581L8.28286 10L3.72481 5.44194C3.48073 5.19786 3.48073 4.80214 3.72481 4.55806ZM10.3915 4.55806C10.6356 4.31398 11.0313 4.31398 11.2754 4.55806L16.2754 9.55806C16.5194 9.80214 16.5194 10.1979 16.2754 10.4419L11.2754 15.4419C11.0313 15.686 10.6356 15.686 10.3915 15.4419C10.1474 15.1979 10.1474 14.8021 10.3915 14.5581L14.9495 10L10.3915 5.44194C10.1474 5.19786 10.1474 4.80214 10.3915 4.55806Z"
    />
  </Icon>
);

ChevronFastRightIcon.displayName = 'ChevronFastRightIcon';

export default ChevronFastRightIcon;
