import { tint } from 'polished';
import styled from 'styled-components';
import { simpleSlideFromTopAnimation } from '../../../animations/simpleAnimation';

export const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  box-shadow: none;
  width: 100%;

  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  & .react-datepicker-popper {
    z-index: 2 !important;
  }

  & .react-datepicker {
    font-family: Roboto;
    border: none;
    margin-bottom: 0;
    border: 1px solid ${({ theme }) => theme.colors.default};
    ${({ theme }) => theme.shadows.elevation__1};
    border-radius: 5px;
    ${simpleSlideFromTopAnimation};
  }
  & .react-datepicker__day {
    color: ${({ theme }) => theme.colors.mainTextColor};
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    margin: 0;
    height: 35px;
    width: 35px;
    line-height: 35px;

    user-select: none;

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      border-radius: 5px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
    }
    &--disabled {
      color: ${({ theme }) => theme.colors.disabled};
    }
  }
  & .react-datepicker__header {
    background-color: unset;
    border-bottom: unset;
  }
  & .react-datepicker__day-names {
    background-color: transparent;
  }
  & .react-datepicker__day-name {
    user-select: none;
    color: ${({ theme }) => theme.colors.tertiary};
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    margin: 9px 7px;
  }
  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.mainTextColor};
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.fonts.size.small};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }
  & .react-datepicker__month {
    margin-top: 0;
  }
  & .react-datepicker__navigation {
    top: 1rem;

    border-radius: ${({ theme }) => theme.borders.radius};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => tint(0.8, theme.colors.primary)};
      outline: none;
    }
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
