import React from 'react';

interface QuestionMarkIconProps {
  color?: string;
}

/* eslint-disable max-len */
const QuestionMarkIcon: React.FC<QuestionMarkIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M13.1667 13.4667C13.1667 5.51167 25.6667 5.51167 25.6667 13.4667C25.6667 19.1483 19.9851 18.0117 19.9851 24.83M20.0001 31.6833L20.0167 31.665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

QuestionMarkIcon.displayName = 'QuestionMarkIcon';

export default QuestionMarkIcon;
