import styled, { css } from 'styled-components';

export const $Form = styled.form<{
  $hasNoRequiredMarked?: boolean;
  $optionnalMessage?: string;
}>`
  ${({ $hasNoRequiredMarked, $optionnalMessage }) =>
    $hasNoRequiredMarked
      ? css`
          label[for] {
            span:only-child:after {
              content: '(${$optionnalMessage || 'optional'})';
              font-weight: ${({ theme }) => theme.fonts.weight.normal};
              margin-left: 4px;
            }
            [class*='Labelstyles__AsteriskSpan'] {
              display: none;
            }
          }
        `
      : undefined};
`;
