import React from 'react';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { useFormContext, Controller } from 'react-hook-form';
import { ClaimGroupDiagnosticTreePageReducerState } from '../../ClaimGroupDiagnosticTreePage.reducer';
import { RadioCardWithCheckboxColumn } from 'myaccount/view/components/RadioCardWithCheckboxColumn';
import { $RadioButtonWrapper, $Question } from './StepAnswers.styles';
import { ClaimGroupDiagnosticTreeValue } from '../../ClaimGroupDiagnosticTreePage.types';

interface StepAnswersProps {
  currentDiagnosticTreeStep: ClaimGroupDiagnosticTreePageReducerState;
  onSubmit: (value: ClaimGroupDiagnosticTreeValue) => void;
}

const StepAnswers: React.FC<StepAnswersProps> = ({
  currentDiagnosticTreeStep,
  onSubmit,
}) => {
  const formContext = useFormContext();

  const { control } = formContext;

  return (
    <FormGroup>
      <$Question>{currentDiagnosticTreeStep?.currentStep?.question}</$Question>
      {currentDiagnosticTreeStep?.currentStep?.responses?.map((response) => {
        return (
          <React.Fragment key={response.uniqueName}>
            <Controller
              control={control}
              name="response"
              render={({ field }) => {
                return (
                  <$RadioButtonWrapper>
                    <RadioCardWithCheckboxColumn
                      title={response.text}
                      name={response.text}
                      value={response.uniqueName}
                      isChecked={
                        field?.value?.uniqueName === response?.uniqueName
                      }
                      onChange={() => field.onChange(response)}
                      onClick={() => onSubmit({ response })}
                    />
                  </$RadioButtonWrapper>
                );
              }}
            />
          </React.Fragment>
        );
      })}
    </FormGroup>
  );
};

StepAnswers.displayName = 'StepAnswers';

export default StepAnswers;
