import { Dictionary } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMedia } from 'react-use';

import {
  HANDLING_GROUPS,
  HANDLING_UNIQUE_NAME,
  media,
} from '@savgroup-front-common/constants';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';
import {
  ChooseItemCard,
  ChooseItemCardMobileView,
} from 'myaccount/view/components/ChooseItemCard';

import { getHandlingData, getHandlingIcon } from '../../../helpers';
import { CHOOSE_ITEM_CARD_TYPE } from '../ChooseItemCard/ChooseItemCard.type';

interface HandlingCardProps {
  groupedCarriers: Dictionary<Carrier[]>;
  setChangedValue?: (value: boolean) => void;
  hasHome?: boolean;
}

const HandlingCard: React.FC<HandlingCardProps> = ({
  groupedCarriers,
  setChangedValue,
  hasHome,
}) => {
  const formContext = useFormContext();
  const isMobileView = useMedia(media.maxWidth.xs);

  const { setValue } = formContext;

  return (
    <>
      {Object.entries(groupedCarriers).map(([key, values]) => {
        const minimumPrice = Math.min(
          ...values.map((carrier) => carrier.priceWithTax || 0),
        );

        const carrier = values.find(
          (carrier) => (carrier.priceWithTax || 0) === minimumPrice,
        );
        const { label, highlighting, description } = getHandlingData({
          carrierUniqueName: hasHome
            ? HANDLING_UNIQUE_NAME.HOME_INTERVENTION
            : (carrier?.carrierTransportMethodUniqueName as HANDLING_UNIQUE_NAME),
        });

        const { productType } = values.at(0) || {
          productType: label.toString(),
        };

        return (
          <>
            {isMobileView && (
              <ChooseItemCardMobileView
                key={productType}
                name="chooseHandling"
                value={key}
                title={label}
                highlighting={highlighting}
                description={description}
                price={carrier?.priceWithTax}
                icon={getHandlingIcon({
                  pickupMode: key as HANDLING_GROUPS,
                })}
                showAddress={
                  key === HANDLING_GROUPS.HOME ||
                  key === HANDLING_GROUPS.EXTERNAL
                }
                handleOnChange={(value) => {
                  if (setChangedValue) {
                    setChangedValue(true);
                  }

                  setValue('chooseHandling', value);
                }}
                chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.HANDLING_CARD}
              />
            )}
            {!isMobileView && (
              <ChooseItemCard
                key={productType}
                name="chooseHandling"
                value={key}
                title={label}
                highlighting={highlighting}
                description={description}
                price={carrier?.priceWithTax}
                icon={getHandlingIcon({
                  pickupMode: key as HANDLING_GROUPS,
                })}
                showAddress={
                  key === HANDLING_GROUPS.HOME ||
                  key === HANDLING_GROUPS.EXTERNAL
                }
                handleOnChange={(value) => {
                  if (setChangedValue) {
                    setChangedValue(true);
                  }

                  setValue('chooseHandling', value);
                }}
                chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.HANDLING_CARD}
              />
            )}
          </>
        );
      })}
    </>
  );
};

HandlingCard.displayName = 'HandlingCard';

export default HandlingCard;
