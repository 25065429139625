import React, { FunctionComponent } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $TabViewTransitionContainer } from './RouteTabs.styles';
import { RouteTabConfig } from './RouteTabs.types';

interface RouteTabsBodyProps {
  config: RouteTabConfig[];
}

const RouteTabsBody: FunctionComponent<RouteTabsBodyProps> = ({ config }) => {
  const location: any = useLocation();
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$TabViewTransitionContainer $isNewUi={isNewUiEnabled}>
      <Switch location={location.location ? location.location : location}>
        {config.map(({ path, url, Component, exact, subTab }) => {
          if (subTab) {
            return subTab.map(({ path, Component, exact, url }) => {
              return (
                <Route key={url} exact={exact} path={path}>
                  {Component && <Component />}
                </Route>
              );
            });
          }

          return (
            <Route key={url} exact={exact} path={path}>
              {Component && <Component />}
            </Route>
          );
        })}
      </Switch>
    </$TabViewTransitionContainer>
  );
};

RouteTabsBody.displayName = 'RouteTabsBody';

export default RouteTabsBody;
