import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import useClaimGroupSolutionPage from './ClaimGroupSolutionPage.hooks';
import {
  $Heading,
  $HeadingIcon,
  $LeftColumn,
  $SolutionItemWrapper,
  $SolutionResultWrapper,
  $SolutionWrapper,
} from './ClaimGroupSolutionPage.styles';
import messages from './messages';
import { SolutionItem } from './SolutionItem';

const ClaimGroupSolutionPage: React.FC = () => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    solutions,
    isSolutionsLoading,
    claimGroupConfirmation,
    formContext,
    onSubmit,
    isSkipLoading,
  } = useClaimGroupSolutionPage();

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  if (isSolutionsLoading || isSkipLoading) {
    return <BaseLoader />;
  }

  return (
    <>
      <FormProvider {...formContext}>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            return undefined;
          }}
        >
          <StepLayout noConfirmButton>
            <$SolutionWrapper>
              <$LeftColumn>
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.LIGHT_BULB_ON_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.solutionTitle}
                  />
                </$Heading>
                <$SolutionItemWrapper>
                  {solutions?.map((solution) => (
                    <SolutionItem
                      key={solution.key}
                      solution={solution}
                      onClick={() =>
                        onSubmit({ chooseSolution: solution.solutionTypeId })
                      }
                    />
                  ))}
                </$SolutionItemWrapper>
              </$LeftColumn>
              <$SolutionResultWrapper>
                {isMobileView ? (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                    handleOpenResumeModal={handleOpenModal}
                  />
                ) : (
                  <ResumeCard claimGroupConfirmation={claimGroupConfirmation} />
                )}
              </$SolutionResultWrapper>
            </$SolutionWrapper>
          </StepLayout>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard claimGroupConfirmation={claimGroupConfirmation} />
      </Modal>
    </>
  );
};

ClaimGroupSolutionPage.displayName = 'ClaimGroupSolutionPage';

export default ClaimGroupSolutionPage;
