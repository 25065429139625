import React, { FC } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { PaymentProvider } from '@savgroup-front-common/types/src/Payment/PaymentProvider';

import { CashRegisterPayment } from './CashRegisterPayment';
import { PaymentStatus } from './CashRegisterPayment/CashRegisterPayment.types';
import { useGetPaymentAccount } from './hooks/useGetPaymentAccount';
import { PaymentMonext } from './PaymentMonext/PaymentMonext';
import { PaymentStripe } from './PaymentStripe';

interface PaymentContentProps {
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  paymentUrl?: string;
  onSubmitSuccess: () => void;
  isFormCompleted: boolean;
  paymentStatus?: PaymentStatus;
  claimGroupId: string;
}

export const PaymentContent: FC<PaymentContentProps> = ({
  paymentUrl,
  invoice,
  isFormCompleted,
  onSubmitSuccess,
  paymentStatus,
  claimGroupId,
}) => {
  const { paymentAccountDto } = useGetPaymentAccount({ claimGroupId });

  const paymentProvider = paymentAccountDto?.paymentProvider;

  return (
    <>
      {!paymentUrl && paymentProvider && invoice && (
        <>
          {paymentProvider === PaymentProvider.STRIPE && (
            <PaymentStripe
              onSubmitSuccess={onSubmitSuccess}
              isFormCompleted={isFormCompleted}
              invoice={invoice}
              claimGroupId={claimGroupId}
            />
          )}

          {paymentProvider === PaymentProvider.MONEXT && <PaymentMonext />}
        </>
      )}

      {paymentUrl && (
        <CashRegisterPayment
          paymentStatus={paymentStatus}
          amount={invoice?.priceWithTax}
          currency={invoice.currencyTrigram}
          isFormCompleted={isFormCompleted}
        />
      )}
    </>
  );
};

PaymentContent.displayName = 'PaymentContent';
