import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ICONS_TYPE,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}issues/forOrder/${COMMON_QUERY_PARAMS.ORDER_ID}`;

interface GetIssuesByOrderReturnValues {
  ownerProductIds: string[];
  sellerProductIds: string[];
  id: string;
  name: string;
  uniqueName: string;
  key: string;
  displayOrder: number;
  didactic: string;
  iconKey: ICONS_TYPE;
  fromProductWarranty: boolean;
  winningWarrantyTypeId: string;
}

interface GetIssuesByOrderArgs {
  orderId: string;
}

const getIssuesByOrder = async ({
  orderId,
}: GetIssuesByOrderArgs): Promise<
  BackResponse<GetIssuesByOrderReturnValues[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);

    const response = await apiCall<
      BackResponse<GetIssuesByOrderReturnValues[]>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getIssuesByOrder;
