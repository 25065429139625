import { media } from '@savgroup-front-common/constants';
import { rem } from 'polished';
import styled from 'styled-components';

export const $HomePageFileItemWrapper = styled.div<{ $isSmall?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
  height: ${({ $isSmall }) => ($isSmall ? rem(140) : 'auto')};
  align-items: ${({ $isSmall }) => ($isSmall ? 'center' : 'stretch')};

  @media ${media.maxWidth.xs} {
    flex-direction: ${({ $isSmall }) => ($isSmall ? 'row' : 'column')};
    padding: 1rem;
    height: ${({ $isSmall }) => ($isSmall ? rem(300) : 'auto')};
  }
`;
