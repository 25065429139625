import { StripeElementLocale } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useIntl } from 'react-intl';

import { PaymentProvider } from '@savgroup-front-common/types/src/Payment/PaymentProvider';

import { useGetPaymentAccount } from '../hooks/useGetPaymentAccount';

import useCreatePaymentIntent from './hooks/useCreatePaymentIntent';

interface UsePaymentStripeArgs {
  invoiceId: string;
  claimGroupId: string;
}

const usePaymentStripe = ({
  invoiceId,
  claimGroupId,
}: UsePaymentStripeArgs) => {
  const intl = useIntl();
  const { paymentAccountDto } = useGetPaymentAccount({ claimGroupId });

  if (paymentAccountDto?.paymentProvider !== PaymentProvider.STRIPE) {
    throw new Error('Payment provider is not Stripe');
  }

  const stripePublicKey = paymentAccountDto.publicToken;

  const stripePromise = loadStripe(stripePublicKey, {
    locale: intl.locale as StripeElementLocale,
  });

  const { paymentIntentClientSecret, isLoading: isCreatePaymentIntentLoading } =
    useCreatePaymentIntent({
      invoiceId,
    });

  const isLoading = isCreatePaymentIntentLoading;

  return {
    stripePromise,
    paymentIntentClientSecret,
    isLoading,
  };
};

export default usePaymentStripe;
