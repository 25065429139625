import { isAfter, isBefore, isValid, parse } from 'date-fns';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import React, { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

import { INPUT_TYPES } from '@savgroup-front-common/constants';

import { safeFormattedIntlString } from '../../../formatters';
import { $Container } from '../common';
import Input from '../Input';

import { formatInputDate } from './Datepicker.helpers';
import { $Wrapper } from './Datepicker.styles';
import { DatePickerProps, LegacyDatePickerPropsRef } from './Datepicker.types';
import messages from './messages';

const dateFormat = 'dd/MM/yyyy';

registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('de', de);

enum DROPDOWN_MODE {
  SCROLL = 'scroll',
  SELECT = 'select',
}

const Datepicker: FC<LegacyDatePickerPropsRef> = ({
  value,
  disabled = false,
  isDisabled = false,
  isLoading = false,
  onBlur = undefined,
  onFocus = undefined,
  placeholder = messages.placeholder,
  name,
  label,
  postLabel,
  isRequired = false,
  onChange = () => undefined,
  minDate,
  maxDate,
  forwardedRef,
  dataTestId = 'date-picker',

  errors = {},
  warnings = {},
  successes = {},

  isError = false,
  isWarning = false,
  isSuccess = false,

  suffix = '',
  prefix = '',

  selectsStart = false,
  selectsEnd = false,
  startDate,
  endDate,

  isClearable = false,
}) => {
  const intl = useIntl();
  const transitionDisabled = disabled || isDisabled;
  const [last, setLast] = useState('');

  const shouldFormat = (value: string) => {
    if (last.length > value.length) {
      return false;
    }

    return true;
  };

  return (
    <$Container>
      <$Wrapper>
        <DatePicker
          selected={value}
          onChange={(date) => {
            onChange(date || undefined);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
          isClearable={isClearable}
          id={name}
          name={name}
          dropdownMode={DROPDOWN_MODE.SELECT}
          minDate={minDate}
          maxDate={maxDate}
          locale={intl.locale}
          onChangeRaw={(event) => {
            if (event.type !== 'change') {
              return;
            }
            const selectionStart = event?.target?.selectionStart;
            const isFocusingLastCaracter =
              event?.target?.value?.length === selectionStart;

            if (shouldFormat(event?.target?.value)) {
              // eslint-disable-next-line no-param-reassign
              event.target.value = formatInputDate(event.target.value);

              if (!isFocusingLastCaracter && selectionStart) {
                event.target.setSelectionRange(selectionStart, selectionStart);
              }
            }

            const date = parse(event.target.value, dateFormat, new Date());

            if (!isValid(date)) {
              setLast(event.target.value);

              return;
            }

            if (
              maxDate &&
              isBefore(date, maxDate) &&
              minDate &&
              isAfter(date, minDate)
            ) {
              onChange(date);
            }
            if (!minDate && maxDate && isBefore(date, maxDate)) {
              onChange(date);
            }
            if (!maxDate && minDate && isAfter(date, minDate)) {
              onChange(date);
            }
            if (!maxDate && !minDate) {
              onChange(date);
            }

            setLast(event.target.value);
          }}
          autoComplete="off"
          ref={forwardedRef}
          customInput={
            <Input
              name={name}
              dataTestId={dataTestId}
              isRequired={isRequired}
              label={label}
              postLabel={postLabel}
              type={INPUT_TYPES.TEXT}
              isError={isError}
              isWarning={isWarning}
              isSuccess={isSuccess}
              errors={errors}
              successes={successes}
              warnings={warnings}
              disableBrowserAutocomplete
              isLoading={isLoading}
              suffix={suffix}
              prefix={prefix}
              data-type="datepicker"
              isDisabled={transitionDisabled}
            />
          }
          dateFormat={dateFormat}
          disabled={transitionDisabled}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholderText={safeFormattedIntlString(placeholder)}
        />
      </$Wrapper>
    </$Container>
  );
};

Datepicker.displayName = 'Datepicker';

export default React.forwardRef<DatePicker, DatePickerProps>((props, ref) => (
  <Datepicker forwardedRef={ref} {...props} />
));
