import { isArray } from 'lodash';

import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';

export const uploadAdditionalInformationFilesToClaim = async ({
  neededInformations,
  reasonAdditionalInformationDocument = {},
  claimId,
}: {
  neededInformations?: ({
    internalId: string;
    fileUploadEndpoints: Record<string, { uploadEndpoint: string }>;
  } & any)[];
  reasonAdditionalInformationDocument?: Record<
    string,
    { value: File } | { value: File }[]
  >;
  claimId: string;
}) => {
  if (!neededInformations) {
    return undefined;
  }

  const neededInformationParams = neededInformations
    .filter((neededInformation) => {
      return (
        reasonAdditionalInformationDocument &&
        neededInformation.fileUploadEndpoints &&
        reasonAdditionalInformationDocument[neededInformation.internalId]
      );
    })
    .map((neededInformation) => {
      const additionalInformationDocument =
        reasonAdditionalInformationDocument[neededInformation.internalId];

      if (isArray(additionalInformationDocument)) {
        return {
          id: neededInformation.id,
          type: neededInformation.type,
          files: additionalInformationDocument.map(({ value }) => value),
          fileUploadUrl: neededInformation.fileUploadEndpoints[claimId],
        };
      }

      return {
        id: neededInformation.id,
        type: neededInformation.type,
        file: additionalInformationDocument?.value,
        fileUploadUrl: neededInformation.fileUploadEndpoints[claimId],
      };
    });
  const responses = [];

  for (const neededInformationParam of neededInformationParams) {
    if (neededInformationParam.files) {
      // eslint-disable-next-line no-await-in-loop
      const response = await CommonAttachmentService.uploadAttachements<string>(
        {
          files: neededInformationParam.files,
          endpoint: neededInformationParam.fileUploadUrl.uploadEndpoint,
        },
      );

      responses.push(response);
    } else if (neededInformationParam.file) {
      // eslint-disable-next-line no-await-in-loop
      const response = await CommonAttachmentService.uploadAttachements<string>(
        {
          files: [neededInformationParam.file],
          endpoint: neededInformationParam.fileUploadUrl.uploadEndpoint,
        },
      );

      responses.push(response);
    }
  }

  return responses;
};
