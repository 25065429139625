import React from 'react';

interface FolderHeartIconProps {
  color?: string;
}

/* eslint-disable max-len */
const FolderHeartIcon: React.FC<FolderHeartIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" color={color}
    >
      <path
        d="M29.3333 5.33333H16.9413L14.6667 3.05867C14.416 2.808 14.0747 2.66667 13.7253 2.66667H2.66667C1.196 2.66667 0 3.86267 0 5.33333V26.6667C0 28.1373 1.196 29.3333 2.66667 29.3333H29.3333C30.804 29.3333 32 28.1373 32 26.6667V8C32 6.52933 30.804 5.33333 29.3333 5.33333ZM29.3333 26.6667H2.66667V13.3333C2.66667 11.8627 3.86267 10.6667 5.33333 10.6667H26.6667C28.1373 10.6667 29.3333 11.8627 29.3333 13.3333V26.6667ZM26.6667 8H5.33333C3.74133 8 2.312 8.7 1.33333 9.808V5.33333C1.33333 4.6 1.93333 4 2.66667 4H13.7253L16 6.27467C16.2507 6.52533 16.592 6.66667 16.9413 6.66667H29.3333C30.0667 6.66667 30.6667 7.26667 30.6667 8V9.808C29.688 8.7 28.2587 8 26.6667 8Z"
        fill="currentColor"
      />
      <path
        d="M19.3333 13.3333C18.2453 13.3333 17.2747 13.8587 16.6667 14.6707C16.0587 13.8627 15.088 13.3333 14 13.3333C12.1627 13.3333 10.6667 14.8293 10.6667 16.6667C10.6667 18.2413 11.6333 19.9453 13.5453 21.7373C14.912 23.016 16.2573 23.8627 16.316 23.9C16.424 23.9667 16.5453 24 16.6707 24C16.796 24 16.916 23.9667 17.0253 23.9C17.084 23.8627 18.4293 23.016 19.796 21.7373C21.704 19.9453 22.6747 18.2413 22.6747 16.6667C22.6667 14.8293 21.1707 13.3333 19.3333 13.3333ZM16.6667 22.5373C15.096 21.4707 12 18.908 12 16.6667C12 15.5627 12.896 14.6667 14 14.6667C15.104 14.6667 16 15.5627 16 16.6667V17.3333H17.3333V16.6667C17.3333 15.5627 18.2293 14.6667 19.3333 14.6667C20.4373 14.6667 21.3333 15.5627 21.3333 16.6667C21.3333 18.908 18.2373 21.4707 16.6667 22.5373Z"
        fill="currentColor"
      />
    </svg>
  );
};

FolderHeartIcon.displayName = 'FolderHeartIcon';

export default FolderHeartIcon;
