import React, { forwardRef, FunctionComponent, Ref } from 'react';

import { $Link, $RouterLink } from './Link.styles';

type LinkProps = {
  href?: string;
  rel?: string;
  isExternal?: boolean;
  dataTestId?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  forwardedRef?: Ref<HTMLAnchorElement>;
  target?: string;
  isWrap?: boolean;
  isInline?: boolean;
  icon?: JSX.Element;
  className?: string;
  isNaked?: boolean;
  disabled?: boolean;
  download?: boolean;
};

const Link: FunctionComponent<React.PropsWithChildren<LinkProps>> = ({
  href = '',
  isExternal = false,
  children,
  dataTestId = null,
  onClick,
  forwardedRef,
  isWrap = false,
  icon = undefined,
  className,
  isInline,
  rel,
  target,
  isNaked,
  disabled,
  download = false,
  ...props
}) => {
  if (isExternal) {
    return (
      <$Link
        {...props}
        data-testid={dataTestId}
        href={href}
        $isWrap={isWrap}
        $isInline={isInline}
        rel={rel}
        target={target}
        ref={forwardedRef}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();

            return undefined;
          }
          if (onClick) {
            onClick(e);

            return undefined;
          }

          e.stopPropagation();

          return undefined;
        }}
        className={className}
        download={download}
      >
        {children}
        {icon && icon}
      </$Link>
    );
  }

  return (
    <$RouterLink
      {...props}
      to={href}
      $isWrap={isWrap}
      $isInline={isInline}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();

          return undefined;
        }
        if (onClick) {
          onClick(e);

          return undefined;
        }

        e.stopPropagation();

        return undefined;
      }}
      ref={forwardedRef}
      data-testid={dataTestId}
      className={className}
      $isNaked={isNaked}
      download={download}
    >
      {children}
      {icon && icon}
    </$RouterLink>
  );
};

Link.displayName = 'Link';

// eslint-disable-next-line react/display-name
export default forwardRef<
  HTMLAnchorElement,
  Omit<React.PropsWithChildren<LinkProps>, 'forwardedRef'>
>((props, ref) => <Link forwardedRef={ref} {...props} />);
