import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ReasonSummaryDto } from '../../types/ReasonSummaryDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/reasons/${COMMON_QUERY_PARAMS.ISSUE_ID}`;

interface GetIssuesForOwnerQueryArgs {
  issueId: string;
  sellerId: string;
}

export const getClaimReasonsByIssueQuery = async ({
  issueId,
  sellerId,
}: GetIssuesForOwnerQueryArgs): Promise<
  BackResponse<ReasonSummaryDto[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ISSUE_ID, issueId);

    return await apiCall<BackResponse<ReasonSummaryDto[]>>(
      buildUrl(url, { sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
