import React from 'react';

interface ConsumableIconProps {
  color?: string;
}

/* eslint-disable max-len */
const ConsumableIcon: React.FC<ConsumableIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50202 1.33337C9.85657 1.33303 11.1835 1.71658 12.329 2.43956C13.2909 3.04668 14.0918 3.87229 14.6687 4.8455V2.50004C14.6687 2.2239 14.8925 2.00004 15.1687 2.00004C15.4448 2.00004 15.6687 2.2239 15.6687 2.50004V5.49804C15.6687 5.71959 15.5807 5.93206 15.424 6.08871C15.2674 6.24537 15.0549 6.33337 14.8334 6.33337H14.8284V6.33335C14.6687 6.33176 14.5128 6.28505 14.3785 6.19862C14.2449 6.11262 14.1382 5.99077 14.0706 5.84703C13.5694 4.79529 12.7805 3.90706 11.7952 3.2852C10.8095 2.66309 9.66774 2.33306 8.50216 2.33337H8.50202C5.30491 2.33337 2.67514 4.76731 2.36625 7.88271C2.339 8.1575 2.09415 8.35818 1.81936 8.33093C1.54456 8.30369 1.34388 8.05884 1.37113 7.78404C1.73024 4.16215 4.78573 1.33344 8.50189 1.33337"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53545 15.6667C7.1809 15.6671 5.85399 15.2835 4.70851 14.5606C3.74659 13.9534 2.9457 13.1278 2.36879 12.1546L2.36879 14.5001C2.36879 14.7762 2.14493 15.0001 1.86879 15.0001C1.59264 15.0001 1.36879 14.7762 1.36879 14.5001L1.36879 11.5021C1.36879 11.2805 1.4568 11.0681 1.61345 10.9114C1.77011 10.7548 1.98258 10.6667 2.20412 10.6667L2.2091 10.6667L2.2091 10.6668C2.36876 10.6684 2.52472 10.7151 2.65897 10.8015C2.79257 10.8875 2.89924 11.0094 2.96684 11.1531C3.46807 12.2048 4.257 13.0931 5.24225 13.7149C6.22793 14.337 7.36973 14.6671 8.53532 14.6667L8.53545 14.6667C11.7326 14.6667 14.3623 12.2328 14.6712 9.11742C14.6985 8.84262 14.9433 8.64194 15.2181 8.66919C15.4929 8.69643 15.6936 8.94129 15.6663 9.21608C15.3072 12.838 12.2517 15.6667 8.53559 15.6667"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50008 6.33337C6.27907 6.33337 6.06711 6.42117 5.91083 6.57745C5.75455 6.73373 5.66675 6.94569 5.66675 7.16671V10.5C5.66675 10.7211 5.75455 10.933 5.91083 11.0893C6.06711 11.2456 6.27907 11.3334 6.50008 11.3334H10.5001C10.7211 11.3334 10.9331 11.2456 11.0893 11.0893C11.2456 10.933 11.3334 10.7211 11.3334 10.5V7.16671C11.3334 6.94569 11.2456 6.73373 11.0893 6.57745C10.9331 6.42117 10.7211 6.33337 10.5001 6.33337H9.00008V7.83337C9.00008 8.10952 8.77622 8.33337 8.50008 8.33337C8.22394 8.33337 8.00008 8.10952 8.00008 7.83337V6.33337H6.50008ZM6.50008 5.33337C6.01385 5.33337 5.54754 5.52653 5.20372 5.87035C4.8599 6.21416 4.66675 6.68048 4.66675 7.16671V10.5C4.66675 10.9863 4.8599 11.4526 5.20372 11.7964C5.54754 12.1402 6.01385 12.3334 6.50008 12.3334H10.5001C10.9863 12.3334 11.4526 12.1402 11.7964 11.7964C12.1403 11.4526 12.3334 10.9863 12.3334 10.5V7.16671C12.3334 6.68048 12.1403 6.21416 11.7964 5.87035C11.4526 5.52653 10.9863 5.33337 10.5001 5.33337H6.50008Z"
        fill="currentColor"
      />
    </svg>
  );
};

ConsumableIcon.displayName = 'ConsumableIcon';

export default ConsumableIcon;
