import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useReducer,
} from 'react';

import { COUNTRY_CODES, EVENT_KEY } from '@savgroup-front-common/constants';

import { useDebounce } from '../../../../../hooks';
import {
  placeAutocompleteInit,
  placeAutocompleteReducer,
} from '../PlacesAutocomplete.reducers';
import {
  Place,
  PLACE_AUTOCOMPLETE_ACTION_TYPES,
  PLACE_TYPE,
  PlaceAutocompleteOnSelectArgs,
} from '../PlacesAutocomplete.types';

import useGoogleMapApi from './useGoogleMapApi';

interface UsePlacesAutocompleteArgs {
  value: string;
  onSelect?: (value: PlaceAutocompleteOnSelectArgs) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  countryCode: string;
  addSecondaryInformationOnSelect: boolean;
}

const usePlacesAutocomplete = ({
  value,
  onSelect,
  onChange,
  countryCode,
  addSecondaryInformationOnSelect,
}: UsePlacesAutocompleteArgs) => {
  const { getPlacesPredictions, getGeocodeByPlaceId } = useGoogleMapApi({
    countryCode: countryCode as COUNTRY_CODES,
  });

  const [
    { lastValue, currentGooglePlaceIdLoading, selectedIndex, places, isOpen },
    dispatch,
  ] = useReducer(placeAutocompleteReducer, {}, placeAutocompleteInit);

  const loadOptions = useCallback(async () => {
    if (value.length > 0 && value !== lastValue && isOpen) {
      try {
        const places = await getPlacesPredictions({
          input: value,
          componentRestrictions: countryCode
            ? { country: countryCode }
            : undefined,
        });

        dispatch({
          type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_END,
          payload: { places, value },
        });
      } catch (error) {
        dispatch({
          type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_FAIL,
          payload: { value },
        });
      }
    }
  }, [value, lastValue, isOpen, getPlacesPredictions, countryCode]);

  useDebounce(loadOptions);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT,
    });
    if (onChange) {
      onChange(event);
    }
  };
  const handleSelect = async (place: Place) => {
    if (onChange) {
      const newValue = [place.structured_formatting.main_text];

      if (addSecondaryInformationOnSelect) {
        newValue.push(place.structured_formatting.secondary_text);
      }
      onChange({
        target: { value: newValue.join(' ') },
        currentTarget: { value: newValue.join(' ') },
      } as any);
    }

    if (place.originType === PLACE_TYPE.GOOGLE) {
      dispatch({
        type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_START,
        payload: { place },
      });
      try {
        const { countryCode, streetNumber, street, city, postalCode } =
          await getGeocodeByPlaceId({
            placeId: place.place_id,
            address: place.structured_formatting.main_text,
          });

        if (onSelect) {
          onSelect({ countryCode, streetNumber, street, city, postalCode });
        }
        dispatch({ type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_END });
      } catch (error) {
        dispatch({ type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_END });
      }
    }

    if (place.originType === PLACE_TYPE.GOUV) {
      if (onSelect) {
        const { city, countryCode, postalCode, street, streetNumber } =
          place.data;

        onSelect({ city, countryCode, postalCode, street, streetNumber });
      }
    }

    dispatch({
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT,
      payload: { value: place.structured_formatting.main_text },
    });
  };

  const shoudlClear = value.length === 0;

  useEffect(() => {
    if (shoudlClear) {
      dispatch({ type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT });
    }
  }, [shoudlClear]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === EVENT_KEY.ARROW_DOWN ||
      event.key === EVENT_KEY.ARROW_UP
    ) {
      dispatch({
        type: PLACE_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION,
        payload: {
          key: event.key,
        },
      });
    }

    if (
      event.key === EVENT_KEY.ENTER &&
      isOpen &&
      typeof selectedIndex === 'number'
    ) {
      handleSelect(places[selectedIndex]);
      event.preventDefault();
    }
  };
  const handleOpen = useCallback(() => {
    dispatch({ type: PLACE_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN });
  }, []);
  const handleClose = useCallback(() => {
    dispatch({ type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN });
  }, []);

  const isLoading = isOpen && value.length > 0 && value !== lastValue;
  const showPlacesDropdown = value.length > 0 && places.length > 0;
  const showLoadingDropdown = isLoading && places.length === 0;

  return {
    isLoading,
    handleKeyDown,
    handleChange,
    currentGooglePlaceIdLoading,
    handleSelect,
    selectedIndex,
    places,
    showPlacesDropdown,
    showLoadingDropdown,
    isOpen: isOpen && (showPlacesDropdown || showLoadingDropdown),
    handleOpen,
    handleClose,
  };
};

export default usePlacesAutocomplete;
