import React from 'react';

interface MultiwindowIconProps {
  color?: string;
}

/* eslint-disable max-len */
const MultiwindowIcon: React.FC<MultiwindowIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M11.6665 31.6667V18.3333C11.6665 17.4493 12.0177 16.6014 12.6428 15.9763C13.2679 15.3512 14.1158 15 14.9998 15H33.3332C34.2172 15 35.0651 15.3512 35.6902 15.9763C36.3153 16.6014 36.6665 17.4493 36.6665 18.3333V31.6667C36.6665 32.5507 36.3153 33.3986 35.6902 34.0237C35.0651 34.6488 34.2172 35 33.3332 35H14.9998C14.1158 35 13.2679 34.6488 12.6428 34.0237C12.0177 33.3986 11.6665 32.5507 11.6665 31.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M10.8333 26.6667H6.66659C5.78253 26.6667 4.93468 26.3155 4.30956 25.6904C3.68444 25.0652 3.33325 24.2174 3.33325 23.3333V10C3.33325 9.11595 3.68444 8.2681 4.30956 7.64298C4.93468 7.01786 5.78253 6.66667 6.66659 6.66667H24.9999C25.884 6.66667 26.7318 7.01786 27.3569 7.64298C27.9821 8.2681 28.3333 9.11595 28.3333 10V15"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M16.6666 20H18.3333M8.33325 11.6667H9.99992"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MultiwindowIcon.displayName = 'MultiwindowIcon';

export default MultiwindowIcon;
