import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  AdditionalClaimInformation,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/additionalInformation`;

interface GetAdditionalInformationByClaimQueryPayload {
  claimId: string;
}

export const getAdditionalInformationByClaimQuery = async ({
  claimId,
}: GetAdditionalInformationByClaimQueryPayload): Promise<
  | BackResponse<
      {
        claimId: string;
        productAdditionalInformation: AdditionalClaimInformation;
      }[]
    >
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    return await apiCall<
      BackResponse<
        {
          claimId: string;
          productAdditionalInformation: AdditionalClaimInformation;
        }[]
      >
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
