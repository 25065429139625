import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponseFailure } from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.carrier}carriers/files/${COMMON_QUERY_PARAMS.FILE_ID}?token=${COMMON_QUERY_PARAMS.TOKEN_ID}`;

interface GetCarrierLabelDocumentArgs {
  fileId: string;
  tokenId: string;
}

const getCarrierLabelDocument = async ({
  fileId,
  tokenId,
}: GetCarrierLabelDocumentArgs): Promise<Blob | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId).replace(
      COMMON_QUERY_PARAMS.TOKEN_ID,
      tokenId,
    );

    const response = await apiCall<Blob>(url, {
      method: SUPPORTED_METHODS.GET,
      responseType: 'blob',
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getCarrierLabelDocument;
