import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { SolutionSummaryDto } from 'myaccount/types/SolutionSummaryDto';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/solutions`;

export interface GetSolutionsByClaimGroupReturnValues {
  selectedWarrantyId: string;
  selectedWarrantyName: string;
  solutions: SolutionSummaryDto[];
}

interface GetSolutionsByClaimGroupArgs {
  claimGroupId: string;
}

const getSolutionsByClaimGroup = async ({
  claimGroupId,
}: GetSolutionsByClaimGroupArgs): Promise<
  BackResponse<GetSolutionsByClaimGroupReturnValues> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<
      BackResponse<GetSolutionsByClaimGroupReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSolutionsByClaimGroup;
