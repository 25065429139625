import { darken } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { FLEX_DIRECTION } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';
import { rem } from '../../../helpers';
import { getTextColorBasedOnProps } from '../../../theme/helpers';

const dot = 30;
const width = 60;
const border = 3;

const smallDot = 17;
const smallWidth = 37;

interface $StyledSwitchProps {
  theme: DefaultTheme;
  disabled?: boolean;
  danger?: boolean;
  alignLabel?: FLEX_DIRECTION;
  $small?: boolean;
}

const colorBasedOnProps = ({ theme, disabled, danger }: $StyledSwitchProps) => {
  if (disabled) {
    return theme.colors.disabled;
  }
  if (danger) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

const sizeBasedOnProps = (isSmall: boolean) => {
  return isSmall
    ? { width: rem(smallWidth), dot: rem(smallDot) }
    : { width: rem(width), dot: rem(dot) };
};

export const $StyledSwitch = styled.span<$StyledSwitchProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $small }) => sizeBasedOnProps(!!$small).width};
  height: ${({ $small }) => sizeBasedOnProps(!!$small).dot};
  border-radius: ${({ $small }) => sizeBasedOnProps(!!$small).width};
  background-color: ${colorBasedOnProps};
  transition-duration: 0.2s;
  ${decelerateTimingFunctionTransition};
  transform-origin: center center;

  &::after {
    content: '';
    display: block;
    position: relative;
    border-radius: 50%;
    transform: translateY(${rem(border)}) translateX(${rem(border)}) scale(1);
    width: ${({ $small }) =>
      $small ? rem(smallDot - border * 2) : rem(dot - border * 2)};
    height: ${({ $small }) =>
      $small ? rem(smallDot - border * 2) : rem(dot - border * 2)};
    background-color: #ffffff;
    box-shadow: 0 ${rem(4)} ${rem(4)} 0 rgba(10, 31, 68, 0.16);
    transition-duration: 0.2s;
    ${decelerateTimingFunctionTransition};
  }
`;

export const $SwitchInput = styled.input.attrs({
  type: 'checkbox',
  role: 'checkbox',
})<{ $small?: boolean }>`
  position: relative;
  z-index: 1;
  width: ${({ $small }) => sizeBasedOnProps(!!$small).width};
  height: ${({ $small }) => sizeBasedOnProps(!!$small).dot};
  opacity: 0;

  &:not(:disabled) {
    cursor: pointer;
    + ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.default};
    }
  }
  &:disabled {
    cursor: not-allowed;
    + ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.disabled};
    }
  }

  &:focus,
  &:hover {
    &:not(:disabled) {
      &:checked + ${$StyledSwitch} {
        background-color: ${({ theme }) => darken(0.15, theme.colors.primary)};
      }
      &:not(:checked) + ${$StyledSwitch} {
        background-color: ${({ theme }) => darken(0.15, theme.colors.default)};
      }
    }
  }

  &:active {
    + ${$StyledSwitch}::after {
      transform: translateY(${rem(border)}) translateX(${rem(border)})
        scale(0.9);
    }
    &:checked + ${$StyledSwitch}::after {
      transform: translateY(${rem(border)})
        translateX(
          ${({ $small }) =>
            $small
              ? rem(smallWidth + border - smallDot)
              : rem(width + border - dot)}
        )
        scale(0.9);
    }
  }

  &:checked {
    + ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.primary};

      &::after {
        transform: translateY(${rem(border)})
          translateX(
            ${({ $small }) =>
              $small
                ? rem(smallWidth + border - smallDot)
                : rem(width + border - dot)}
          )
          scale(1);
      }
    }
  }
`;

const SwitchContainerMixin = css<$StyledSwitchProps>`
  display: flex;
  position: relative;
  flex-direction: ${({ alignLabel }) =>
    alignLabel || FLEX_DIRECTION.ROW_REVERSE};
  color: ${getTextColorBasedOnProps};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  span {
    line-height: ${rem(dot)};
  }

  ${({ $small }) =>
    $small
      ? css`
          font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
          font-weight: ${({ theme }) => theme.fonts.weight.normal};
          line-height: 14px;
          align-items: center;
        `
      : undefined};
`;

export const $Label = styled.label<$StyledSwitchProps>`
  ${SwitchContainerMixin}
`;
export const $LabelContainer = styled.div<{ alignLabel?: FLEX_DIRECTION }>`
  flex: 1;
  display: flex;
  align-items: center;
  margin: ${({ alignLabel }) => {
    return alignLabel === FLEX_DIRECTION.COLUMN ? '0.5rem 0' : '0 0.5rem 0 0';
  }};
  & span {
    line-height: 1.2 !important;
  }
`;
export const $SwitchContainer = styled.div<$StyledSwitchProps>`
  ${SwitchContainerMixin}
`;
