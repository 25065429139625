import React, {
  FC,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MessageType } from '@savgroup-front-common/types';
import { SafeFormattedMessage } from '../../../../formatters';
import { useCombinedRefs } from '../../../../hooks';
import {
  $ComplexSwitchContainer,
  $ComplexSwitchButton,
} from './ComplexSwitch.styles';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { matchPath, useRouteMatch } from 'react-router-dom';
import { getDefaultValue } from './ComplexSwitch.helpers';

export interface ComplexSwitchSide {
  label?: MessageType | string;
  path?: string;
}

export interface ComplexSwitchProps {
  leftSide?: ComplexSwitchSide;
  rightSide?: ComplexSwitchSide;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  onChange?: (value: number) => void;
  defaultValue?: number;
}

interface ComplexSwitchPropsRef extends ComplexSwitchProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const ComplexSwitch: FC<ComplexSwitchPropsRef> = ({
  leftSide,
  forwardedRef,
  rightSide,
  leftIcon,
  rightIcon,
  onChange,
  defaultValue,
}) => {
  const match = useRouteMatch();
  const defaultActiveSide = getDefaultValue({
    url: match.url,
    defaultValue,
    leftSide,
    rightSide,
  });

  const [activeSide, setActiveSide] = useState(defaultActiveSide);
  const complexSwitchRef = useRef<HTMLInputElement | null>(null);
  const combinedRefs = useCombinedRefs(forwardedRef, complexSwitchRef);

  const handleChange = useCallback(
    (value: number) => {
      setActiveSide(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (
      defaultActiveSide === defaultValue &&
      !matchPath(match.url, {
        path: defaultActiveSide === 0 ? leftSide?.path : rightSide?.path,
      })
    ) {
      handleChange(defaultActiveSide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <$ComplexSwitchContainer ref={combinedRefs}>
      {leftSide?.label && (
        <$ComplexSwitchButton
          type={BUTTON_TYPES.BUTTON}
          secondary={activeSide === 0}
          naked={activeSide === 1}
          icon={leftIcon}
          onClick={() => {
            if (activeSide === 1) {
              handleChange(0);
            }
          }}
          $isActive={activeSide === 0}
        >
          {SafeFormattedMessage(leftSide.label)}
        </$ComplexSwitchButton>
      )}
      {rightSide?.label && (
        <$ComplexSwitchButton
          type={BUTTON_TYPES.BUTTON}
          icon={rightIcon}
          secondary={activeSide === 1}
          naked={activeSide === 0}
          onClick={() => {
            if (activeSide === 0) {
              handleChange(1);
            }
          }}
          $isActive={activeSide === 1}
        >
          {SafeFormattedMessage(rightSide.label)}
        </$ComplexSwitchButton>
      )}
    </$ComplexSwitchContainer>
  );
};

ComplexSwitch.displayName = 'ComplexSwitch';

export default React.forwardRef<HTMLInputElement, ComplexSwitchProps>(
  (props, ref) => <ComplexSwitch forwardedRef={ref} {...props} />,
);
