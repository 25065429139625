import { rgba, tint } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import {
  FIELD_STATUS,
  InputStatus,
} from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import { rem } from '@savgroup-front-common/core/src/helpers';
import { Theme } from '@savgroup-front-common/types';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';

interface $SolutionProps {
  $status: InputStatus;
}

const getStatusColor = (
  {
    $status,
    theme,
  }: {
    $status?: InputStatus;
    theme: Theme;
  },
  defaultColor?: string,
) => {
  switch ($status) {
    case FIELD_STATUS.ERROR:
      return theme.colors.danger;
    case FIELD_STATUS.WARNING:
      return theme.colors.alert;
    case FIELD_STATUS.SUCCESS:
      return theme.colors.success;
    default:
      return defaultColor || theme.colors.primary;
  }
};

interface RadioCardInfoProps {
  isChecked?: boolean;
  isDisabled?: boolean;
}

export const $RadioCardInfo = styled.div<RadioCardInfoProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  background: transparent;
`;

interface ChooseProductCardCheckboxContainerProps {
  isChecked?: boolean;
}

export const $ChooseProductCardCheckboxContainer = styled(
  'div',
)<ChooseProductCardCheckboxContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 17px;
  border: 1px solid
    ${({ theme, isChecked }) => (isChecked ? theme.colors.primary : '#c9ced6')};
  box-sizing: border-box;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.primary : 'transparent'};
`;

export const $PostTitle = styled.div`
  display: flex;
`;

interface $TextContainerProps {
  hasImage: boolean;
}

export const $TextContainer = styled.div<$TextContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: fit-content;
  align-items: flex-start;
  padding-left: ${({ hasImage }) => (hasImage ? '8px' : 'unset')};
  @media ${media.minWidth.sm} {
    align-items: center;
    padding-left: unset;
  }
  text-align: center;
`;

export const $ImageHoveredContainer = styled.div<$SolutionProps>`
  display: none;
  align-items: flex-start;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  svg {
    @media ${media.minWidth.sm} {
      width: 32px;
    }
    width: 24px;
    height: auto;
  }
`;

export const $ImageContainer = styled.div<$SolutionProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  svg {
    @media ${media.minWidth.sm} {
      width: 32px;
    }
    width: 24px;
    height: auto;
  }
`;

export const $RadioCardTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.mainTextColor};
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  @media ${media.minWidth.sm} {
    line-height: 18px;
    padding-bottom: 8px;
  }
`;

export const $RadioCardLabel = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface RadioCardBodyProps {
  hasImage: boolean;
}

export const $RadioCardBody = styled.div<RadioCardBodyProps>`
  flex: 1;
  padding-left: unset;
  margin-top: 1rem;
  @media ${media.minWidth.sm} {
    padding-left: ${({ hasImage }) => (hasImage ? '24px' : 'unset')};
    margin-top: unset;
  }

  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

export const $RadioCardContainer = styled.div<$SolutionProps>`
  display: flex;
  flex-direction: column;
  @media ${media.minWidth.sm} {
    padding: 24px;
  }
  border-width: ${rem(1)};
  border-style: solid;
  border-color: ${({ theme, $status }) =>
    getStatusColor({ theme, $status }, theme.colors.default)};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borders.radius};

  cursor: pointer;
  margin-bottom: 1rem;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  padding: 12px;
  flex: 1;
`;

export const $RadioCardFirstLine = styled.div<$SolutionProps>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media ${media.minWidth.sm} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  flex: 1;
`;

export const $RadioCardLabelContainer = styled.label<$SolutionProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 90px;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  input:hover,
  input:focus,
  input:active {
    ~ ${$RadioCardContainer} {
      border-color: ${getStatusColor};

      ${$ImageHoveredContainer} {
        display: flex;
      }
      ${$ImageContainer} {
        display: none;
      }
    }
  }
  input:checked {
    ~ ${$RadioCardContainer} {
      border-color: ${getStatusColor};
      box-shadow: ${({ theme }) =>
        `0px 0px 4px ${rgba(getStatusColor({ theme }), 0.4)}`};
      ${$RadioCardTitle} {
        color: ${getStatusColor};
      }
      ${$RadioCardLabel} {
        color: ${getStatusColor};
      }
      ${$ImageContainer} {
        border-color: ${getStatusColor};
      }
      background-color: ${({ theme, $status }) =>
        tint(0.96, getStatusColor({ theme, $status }))};
    }
  }
  span[class*='Checkboxstyles'] {
    display: none;
  }
`;

export const $RadioCardSubLine = styled.div`
  border-top: 1px solid #e1e4e8;
  margin-top: 1rem;
  padding-top: 1rem;
`;
