import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CIVILITY,
  COMMON_QUERY_PARAMS,
  HOUSING_TYPES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/carrier`;

interface Address {
  addressId: string;
  civility: CIVILITY;
  firstname: string;
  lastname: string;
  address: string;
  additionalAddress: string;
  housingType: HOUSING_TYPES;
  doorCode: string;
  floor: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  companyName: string;
  additionalInformation: string;
  isDefault: boolean;
}

interface GetCarrierSummaryByClaimGroupReturnValues {
  claimGroupId: string;
  depositStore: string;
  purchaseStore: string;
  storeActor: string;
  deliveryStore: string;
  depositAddress: Address;
  carrierDepositProductId: string;
  carrierDepositCostId: string;
  carrierDepositCost: number;
  carrierDepositCustomerPriceId: string;
  carrierDepositSellerPriceId: string;
  carrierDepositCustomerPrice: number;
  carrierDepositSellerPrice: number;
  carrierDepositProductName: string;
  carrierDepositCarrierUniqueName: string;
  deliveryAddress: Address;
  carrierDeliveryProductId: string;
  carrierDeliveryCostId: string;
  carrierDeliveryCost: number;
  carrierDeliveryCustomerPriceId: string;
  carrierDeliverySellerPriceId: string;
  carrierDeliveryCustomerPrice: number;
  carrierDeliverySellerPrice: number;
  carrierDeliveryProductName: string;
  carrierDeliveryCarrierUniqueName: string;
  currencyCode: string;
  paymentBehaviour: string;
}

interface GetCarrierSummaryByClaimGroupArgs {
  claimGroupsId: string;
}

const getCarrierSummaryByClaimGroup = async ({
  claimGroupsId,
}: GetCarrierSummaryByClaimGroupArgs): Promise<
  BackResponse<GetCarrierSummaryByClaimGroupReturnValues> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupsId,
    );

    const response = await apiCall<
      BackResponse<GetCarrierSummaryByClaimGroupReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getCarrierSummaryByClaimGroup;
