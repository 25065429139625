import React, {
  FocusEventHandler,
  FunctionComponent,
  MutableRefObject,
} from 'react';
import { UseFormRegister } from 'react-hook-form';

import Textarea from '../../../atoms/Form/Textarea';
import { TextAreaComponentProps } from '../../../atoms/Form/Textarea/Textarea';

function mergeRefs(
  refs: Array<
    | MutableRefObject<HTMLTextAreaElement | null>
    | ((instance: HTMLTextAreaElement | null) => void)
    | null
  >,
): React.RefCallback<HTMLTextAreaElement | null> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}

interface TextareaHookFormProps
  extends Exclude<TextAreaComponentProps, 'onChange'> {
  register: UseFormRegister<any>;
  name: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}
interface TextareaHookFormPWithRef extends TextareaHookFormProps {
  forwardedRef:
    | MutableRefObject<HTMLTextAreaElement | null>
    | ((instance: HTMLTextAreaElement | null) => void)
    | null;
}

const TextareaHookForm: FunctionComponent<
  React.PropsWithChildren<TextareaHookFormPWithRef>
> = ({ register, name, forwardedRef, onBlur, ...rest }) => {
  const { ref, ...resgisteredInput } = register(name);

  return (
    <Textarea
      {...rest}
      {...resgisteredInput}
      ref={mergeRefs([ref, forwardedRef])}
      onBlur={onBlur}
    />
  );
};

TextareaHookForm.displayName = 'TextareaHookForm';

export default React.forwardRef<HTMLTextAreaElement, TextareaHookFormProps>(
  (props, ref) => <TextareaHookForm forwardedRef={ref} {...props} />,
);
