import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Img } from '@savgroup-front-common/core/src/atoms/Img';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import PickupPointItemInfo from '@savgroup-front-common/core/src/components/PickupPointItemInfo';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { PickupPoint } from '@savgroup-front-common/types';
import {
  ChooseItemCard,
  ChooseItemCardMobileView,
} from 'myaccount/view/components/ChooseItemCard';

import { CHOOSE_ITEM_CARD_TYPE } from '../../ChooseItemCard/ChooseItemCard.type';
import messages from '../messages';

import { getUrl } from './PickupPointList.helpers';
import { $Button, $Container } from './PickupPointList.styles';

interface PickupPointListProps {
  handleSetSelectedPickupPoint: (pickupPointId: string) => void;
  sellerId?: string;
  pickupPoints: PickupPoint[];
}

const PickupPointList: React.FC<PickupPointListProps> = ({
  handleSetSelectedPickupPoint,
  pickupPoints,
  sellerId,
}) => {
  const [pickupPointSelected, setPickupPointSelected] = useState<
    PickupPoint | undefined
  >(undefined);
  const isMobileView = useMedia(media.maxWidth.xs);
  const intl = useIntl();

  return (
    <>
      <$Container>
        {sellerId &&
          pickupPoints.map((pickupPoint) => {
            const normalizedDistance =
              pickupPoint.distanceInMeters < 1000
                ? pickupPoint.distanceInMeters
                : Math.round(pickupPoint.distanceInMeters / 100) / 10;
            const distanceUnit =
              pickupPoint.distanceInMeters < 1000 ? 'm' : 'km';

            return (
              <>
                {isMobileView && (
                  <ChooseItemCardMobileView
                    key={pickupPoint.id}
                    name="chooseHandlingPickupPoint"
                    value={pickupPoint.id}
                    title={pickupPoint.name}
                    handleOnChange={handleSetSelectedPickupPoint}
                    description={`${pickupPoint.adress}\n${pickupPoint.postalCode} ${pickupPoint.city}`}
                    distance={`${safeFormattedIntlString(
                      messages.distance,
                    )} ${intl.formatNumber(normalizedDistance)}${distanceUnit}`}
                    price={pickupPoint.priceWithTax}
                    logo={
                      <Img
                        alt={pickupPoint.carrierName}
                        urls={getUrl({ sellerId, pickupPoint })}
                      />
                    }
                    actionButton={
                      <$Button
                        underline
                        onClick={() => setPickupPointSelected(pickupPoint)}
                        type={BUTTON_TYPES.BUTTON}
                      >
                        {safeFormattedIntlString(messages.openingHours)}
                      </$Button>
                    }
                    hasDisplayFreeAmount={false}
                    chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.PICKUP_POINT_CARD}
                  />
                )}
                {!isMobileView && (
                  <ChooseItemCard
                    key={pickupPoint.id}
                    name="chooseHandlingPickupPoint"
                    value={pickupPoint.id}
                    title={pickupPoint.name}
                    handleOnChange={handleSetSelectedPickupPoint}
                    description={`${pickupPoint.adress}\n${pickupPoint.postalCode} ${pickupPoint.city}`}
                    distance={`${safeFormattedIntlString(
                      messages.distance,
                    )} ${intl.formatNumber(normalizedDistance)}${distanceUnit}`}
                    price={pickupPoint.priceWithTax}
                    logo={
                      <Img
                        alt={pickupPoint.carrierName}
                        urls={getUrl({ sellerId, pickupPoint })}
                      />
                    }
                    actionButton={
                      <$Button
                        underline
                        onClick={() => setPickupPointSelected(pickupPoint)}
                        type={BUTTON_TYPES.BUTTON}
                      >
                        {safeFormattedIntlString(messages.openingHours)}
                      </$Button>
                    }
                    hasDisplayFreeAmount={false}
                    chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.PICKUP_POINT_CARD}
                  />
                )}
              </>
            );
          })}
      </$Container>
      {pickupPointSelected && (
        <Modal
          size={MODAL_SIZES.SMALL}
          isOpen={!!pickupPointSelected}
          onClose={() => setPickupPointSelected(undefined)}
        >
          <PickupPointItemInfo
            openingHours={pickupPointSelected.openingHours}
          />
          <Button
            primary
            fluid
            hollow
            onClick={() => setPickupPointSelected(undefined)}
            type={BUTTON_TYPES.BUTTON}
          >
            <SafeFormattedMessageWithoutSpread message={messages.close} />
          </Button>
        </Modal>
      )}
    </>
  );
};

PickupPointList.displayName = 'PickupPointList';

export default PickupPointList;
