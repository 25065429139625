import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CIVILITY,
  COMMON_QUERY_PARAMS,
  HOUSING_TYPES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/confirmation`;

interface Address {
  addressId: string;
  civility: CIVILITY;
  firstname: string;
  lastname: string;
  address: string;
  additionalAddress: string;
  housingType: HOUSING_TYPES;
  doorCode: string;
  floor: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  companyName: string;
  additionalInformation: string;
  isDefault: boolean;
}

interface GetConfirmationInfoByClaimGroupReturnValues {
  claimGroupId: string;
  solutionTypeName: string;
  solutionPrice: number;
  solutionPriceCurrencyCode: string;
  solutionPriceWithTax: number;
  solutionPriceWithTaxCurrencyCode: string;
  solutionGroupId: string;
  depositStoreId: string;
  purchaseStoreId: string;
  storeActorId: string;
  actorId: string;
  actorType: string;
  depositAddress: Address;
  deliveryAddress: Address;
  carrierDepositName: string;
  carrierDeliveryName: string;
  carrierDeliveryPrice: number;
  carrierDeliveryPriceCurrencyCode: string;
  carrierDepositPrice: number;
  carrierDepositPriceCurrencyCode: string;
  carrierDeliveryPriceWithTax: number;
  carrierDeliveryPriceWithTaxCurrencyCode: string;
  carrierDepositPriceWithTax: number;
  carrierDepositPriceWithTaxCurrencyCode: string;
  claims: {
    claimId: string;
    issueName: string;
    reasonName: string;
    comments: {
      type: string;
      content: string;
    }[];
    documents: {
      name: string;
      originalFileName: string;
      extension: string;
      url: string;
      id: string;
      uploaderId: string;
      uploaderFullName: string;
      uploaderType: string;
      uploadDateUtc: Date;
      customerVisibility: boolean;
    }[];
    solutionTypeName: string;
    solutionTypeKey: string;
    solutionPrice: number;
    solutionPriceCurrency: string;
    solutionGroupId: string;
    additionalClaimInformation: {
      additionalInformationId: string;
      additionalInformationEnumValue: string;
      additionalInformationStringValue: string;
      additionalInformationFilesValue?: {
        blobName: string;
        customerVisibility: boolean;
        isUploadedByOwner: boolean;
        uploadDateUtc: string;
        uploaderFullName: string;
        uploaderId: string;
        fileName: string;
        url: string;
      }[];
      additionalInformationFileValue: {
        fileName: string;
        url: string;
        uploaderId: string;
        uploaderFullName: string;
        isUploadedByOwner: boolean;
        uploadDateUtc: Date;
        customerVisibility: boolean;
      };
    }[];
  }[];
  shouldGenerateAndPayInvoice: boolean;
  paymentBehavior: string;
  carrierDepositReach: string;
  carrierDeliveryReach: string;
  carrierDepositProductType: string;
  carrierDeliveryProductType: string;
}

interface GetConfirmationInfoByClaimGroupArgs {
  claimGroupId: string;
}

const getConfirmationInfoByClaimGroup = async ({
  claimGroupId,
}: GetConfirmationInfoByClaimGroupArgs): Promise<
  | BackResponse<GetConfirmationInfoByClaimGroupReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<
      BackResponse<GetConfirmationInfoByClaimGroupReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getConfirmationInfoByClaimGroup;
