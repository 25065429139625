import React from 'react';

interface LightBulbOnIconProps {
  color?: string;
}

/* eslint-disable max-len */
const LightBulbOnIcon: React.FC<LightBulbOnIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M35 3.33333L33.3333 5M5 3.33333L6.66667 5M35 26.6667L33.3333 25M5 26.6667L6.66667 25M15 30H25M16.6667 35H23.3333M20 5C13.3333 5 9.92 8.25 10 13.3333C10.0383 15.8117 10.8333 17.5 12.5 19.1667C14.1667 20.8333 15 21.6667 15 25H25C25 21.6667 25.8333 20.8333 27.5 19.1667C29.1667 17.5 29.9617 15.8117 30 13.3333C30.08 8.25 26.6667 5 20 5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LightBulbOnIcon.displayName = 'LightBulbOnIcon';

export default LightBulbOnIcon;
