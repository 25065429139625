import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CIVILITY,
  COMMON_QUERY_PARAMS,
  HOUSING_TYPES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/confirmation`;

interface HandlingAddress {
  addressId: string;
  civility: CIVILITY;
  firstname: string;
  lastname: string;
  address: string;
  additionalAddress: string;
  housingType: HOUSING_TYPES;
  doorCode: string;
  floor: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  companyName: string;
  additionalInformation: string;
  isDefault: boolean;
}

interface GetConfirmationInfoByClaimReturnValues {
  claimId: string;
  issueName: string;
  reasonName: string;
  solutionTypeName: string;
  comments: {
    type: string;
    content: string;
  }[];
  solutionPrice: number;
  solutionPriceWithTax: number;
  solutionPriceCurrencyCode: string;
  solutionGroupId: string;
  additionalElements: string[];
  depositAddress: HandlingAddress;
  deliveryAddress: HandlingAddress;
  carrierDepositName: string;
  carrierDeliveryName: string;
  carrierDeliveryPrice: number;
  carrierDeliveryPriceWithTax: number;
  carrierDeliveryPriceCurrencyCode: string;
  carrierDepositPrice: number;
  carrierDepositPriceWithTax: number;
  carrierDepositPriceCurrencyCode: string;
  documents: {
    name: string;
    originalFileName: string;
    extension: string;
    url: string;
    id: string;
    uploaderId: string;
    uploaderFullName: string;
    uploaderType: string;
    uploadDateUtc: Date;
    customerVisibility: boolean;
  }[];
  shouldGenerateAndPayInvoice: boolean;
  paymentBehavior: string;
}

interface GetConfirmationInfoByClaimArgs {
  claimId: string;
}

const getConfirmationInfoByClaim = async ({
  claimId,
}: GetConfirmationInfoByClaimArgs): Promise<
  BackResponse<GetConfirmationInfoByClaimReturnValues> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<
      BackResponse<GetConfirmationInfoByClaimReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getConfirmationInfoByClaim;
